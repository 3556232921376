import React, { useEffect, useState, useReducer } from 'react';
import http from '../../hooks/http';
import useAlert from '../../hooks/alert';

import styles from './Orders.module.scss';
import Alert from '../../components/UI/Alert/Alert';
import OrderList from './OrderList/OrderList';
import Modal from '../../components/UI/Modal/Modal';
import OrderDetails from './OrderDetails/OrderDetails';

// type currentInventory = {};

export type Purchaser = {
  _id: string;
  id: string;
  email: string;
  phoneNumber: string;
  name: string;
};

export type Product = {
  _id: string;
  availability: string;
  color: string;
  id: string;
  name: string;
  potentialProfit?: number;
  price: number;
  size: string;
  sku: string;
};

export type Order = {
  id: number;
  cash: boolean;
  orderNo: string;
  total: number;
  status: string;
  products?: Product[];
  purchaser: Purchaser;
  datePurchased: Date;
  orderNote: string;
};

const orderReducer = (
  currentInventory: Order[],
  action: { type: 'SET'; orders: Order[] }
) => {
  switch (action.type) {
    case 'SET':
      return action.orders;
    default:
      throw new Error('SHOULD NOT HAPPEN!');
  }
};

const Orders = () => {
  const { resData, sendRequest, isLoading, error, reqId } = http();
  const { setAlert, isAlert, message, type } = useAlert();
  const [orders, dispatch] = useReducer(orderReducer, []);
  const [order, setOrder] = useState<Order | undefined>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    sendRequest(
      'orders?sort=-datePurchased',
      'GET',
      undefined,
      undefined,
      'set'
    );
  }, []);

  useEffect(() => {
    if (error) return setAlert('Error', error);
    if (isLoading) return;

    switch (reqId) {
      case 'set':
        return dispatch({ type: 'SET', orders: resData.data.data.data });
      default:
    }
  }, [isLoading, error, reqId, resData, setAlert]);

  const selectOrder = (id: number) => {
    setOrder(orders.find(el => el.id === id));
    setShow(true);
  };

  const toggleModal = () => {
    setShow(!show);
  };

  return (
    <div className={styles.orders}>
      <Modal show={show} modalClosed={toggleModal} noPadding>
        <OrderDetails order={order} />
      </Modal>
      {isAlert && <Alert msg={message} alertType={type} />}

      <OrderList orders={orders} clicked={selectOrder} />
    </div>
  );
};

export default Orders;
