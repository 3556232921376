import React from 'react';
import axios from '../../axios-lab';
import { useQuery } from '@tanstack/react-query';
import styles from './LeadDashboard.module.scss';

type Lead = {
  id: string;
  _id: string;
  name: boolean;
  email: boolean;
  phoneNumber: string;
  touched: boolean;
  createdAt: Date;
  spam: boolean;
};

const LeadDashboard = () => {
  // const [leads, setLeads] = React.useState<Lead[]>([]);
  const getLeads = async () => {
    const { data } = await axios.get(`leads`);
    console.log(data.data);

    return data.data.data as Lead[];
  };

  const {
    data: leads = [],
    error,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['leads'],
    queryFn: getLeads,
  });

  console.log(leads);

  // if (leads.length > 0) {
  //   return (
  //     <div>
  //       {leads.map(lead => (
  //         <div>
  //           <p>{lead.name}</p>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }

  return (
    <div>
      <p>Hey this is new</p>
      {leads.map(lead => (
        <div className={styles.leadBox}>
          <p>{lead.name}</p>
          <p>{lead.email}</p>
          <p>{lead.phoneNumber}</p>
          <p>{new Date(lead.createdAt).toLocaleDateString()}</p>
          <p>{new Date(lead.createdAt).toLocaleTimeString()}</p>
        </div>
      ))}
    </div>
  );
};

export default LeadDashboard;
