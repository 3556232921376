import React, { useState, useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import useAlert from '../../hooks/alert';
import classes from './MembershipDashboard.module.scss';
import Button from '../../components/UI/Button/Button';
import Alert from '../../components/UI/Alert/Alert';
import Modal from '../../components/UI/Modal/Modal';
import MembershipForm from '../../components/Forms/MembershipForm/MembershipForm';
import UserSearchModal from '../../components/UserSearchModal/UserSearchModal';
import MembershipCard from './MembershipCard/MembershipCard';
import SelectedMember from './SelectedMember/SelectedMember';
import axios from '../../axios-lab';
import Spinner from '../../components/UI/Spinner/Spinner';
import { wait } from '../../utils/utils';
import SortBox from './SortBox/SortBox';
import { AxiosError } from 'axios';

const statusArr = ['active', 'cancelled', 'paused'];

export interface Membership {
  id: string;
  paymentMethod: string;
  nextDue: string;
  accountHolder: AccountHolder;
  student: Student;
  monthlyTotal: number;
  status: string;
  monthlyBasePrice: number;
  monthlyDiscount: number;
  totalDiscount: number;
  totalDue: number;
  membershipType: string;
}

export interface AccountHolder {
  id: string;
  name: string;
}

interface Student {
  id: string;
  name: string;
}

const MembershipDashboard = () => {
  const { setAlert, isAlert, message, type } = useAlert();
  const [selectedMembership, setSelectedMembership] = useState<Membership>();
  const [memberShow, setMemberShow] = useState(false);
  const [createShow, setCreateShow] = useState(false);
  const [show, setShow] = useState(false);
  const [accountHolder, setAccountHolder] = useState<AccountHolder | null>();
  const [userShow, setUserShow] = useState(false);
  const [memberEdit, setMemberEdit] = useState(false);
  const [membershipType, setMembershipType] = useState('youth');
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [queryName, setQueryName] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const [searchParams, setSearchParams] = useState('status=active');

  const queryClient = useQueryClient();

  const queryParams = new URLSearchParams('status=active');

  const getMemberships = async () => {
    const { data } = await axios.get(`memberships?${searchParams}`);
    return data.data.data as Membership[];
  };

  const {
    data: memberships = [],
    error,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['memberships', searchParams],
    queryFn: getMemberships,
  });

  const axiosMembers = async () => {
    await wait(0.7);
    if (inputRef.current && queryName === inputRef.current.value) {
      const { data } = await axios.get(`users?like=${inputRef.current.value}`);
      return data.data.users;
    }
  };

  const { data: mems } = useQuery({
    queryKey: ['mems', queryName],
    queryFn: axiosMembers,
    enabled: !!queryName,
    initialData: [],
  });

  console.log('memberships');
  console.log(mems);
  console.log(memberships);
  console.log(accountHolder);

  const getStudents = async () => {
    if (!accountHolder) return;
    const { data } = await axios.get(`users/${accountHolder.id}`);
    return data.data.data.students as Student[];
  };
  const { data: students = [] } = useQuery({
    queryKey: ['students'],
    queryFn: getStudents,
    enabled: !!accountHolder,
    initialData: [],
  });

  const selectUser = (id: string) => {
    setAccountHolder(mems.find((el: any) => el.id === id));
    setUserShow(false);
    setQueryName('');
  };

  const selectMembership = (membership: any) => {
    setSelectedMembership(membership);
    setMemberShow(true);
    setShow(true);
  };

  const selectStudent = (id: string) => {
    const potentialStudent = students.find(el => el.id === id);
    if (!potentialStudent) return;
    setSelectedStudent(potentialStudent);
  };

  const closeModal = () => {
    setMemberShow(false);
    setCreateShow(false);
    setShow(!show);
  };

  const openCreateModal = () => {
    setCreateShow(true);
    setShow(true);
  };

  const openMembershipEditModal = () => {
    setMemberEdit(true);
    setShow(true);
  };

  const deleteMembership = useMutation({
    mutationFn: (id: string) => {
      return axios.delete(`memberships/${id}`);
    },
    onError: (error: any, variables, context) => {
      setAlert('Error', error.response.data.message);
    },
    onSuccess: (data, variables, context) => {
      setAlert('Success', 'Successfully cancelled membership!');
      setShow(false);
      // queryClient.setQueryData('memberships', oldData =>
      //   oldData.filter(el => el.id !== variables)
      // );
      queryClient.invalidateQueries({ queryKey: ['memberships'] });
    },
  });

  // if (!window.confirm(`Cancel Membership "${id}"?`)) return;

  const createMembership = useMutation({
    mutationFn: (formData: any) => {
      const postObj = {
        accountHolder: accountHolder?.id,
        student: selectedStudent?.id,
        membershipType: 'adult',
        ...formData,
      };

      setSelectedStudent(null);

      return axios.post('/memberships', postObj);
    },
    onError: (error: any, variables, context) => {
      setAlert('Error', error.response.data.message);
    },
    onSuccess: async (data, variables, context) => {
      setShow(false);
      setAlert('Success', 'Successfully created new membership!');
      await queryClient.invalidateQueries({ queryKey: ['memberships'] });
    },
  });

  const updateMembershipType = useMutation({
    mutationFn: memType => {
      const postObj = {
        membershipType: membershipType,
      };

      return axios.patch(`/memberships/${selectedMembership?.id}`, postObj);
    },
    onError: (error: any, variables, context) => {
      setAlert('Error', error.response.data.message);
      // setSelectedMembership(null);
      setMemberEdit(false);
    },
    onSuccess: async () => {
      setShow(false);
      setAlert('Success', 'Successfully changed Membership Type!');
      setMemberEdit(false);
      await queryClient.invalidateQueries({ queryKey: ['memberships'] });
    },
  });

  const showUserSearchMod = () => {
    setAccountHolder(null);
    setSelectedStudent(null);
    setUserShow(true);
    setShow(true);
  };

  const updateSearchParams = (paramKey: string, paramVal: string) => {
    const deezParams = new URLSearchParams(searchParams);

    deezParams.set(paramKey, paramVal);

    setSearchParams(deezParams.toString());
  };

  let modalContent;

  if (createShow) {
    modalContent = (
      <div
        style={{
          position: 'relative',
        }}
      >
        <div className={classes.user}>
          <p onClick={showUserSearchMod} className={classes.num}>
            Account Holder: {accountHolder?.name}
          </p>
          <p>Student: {selectedStudent?.name}</p>
          {selectedStudent
            ? null
            : students.map(stud => (
                <p key={stud.id} onClick={el => selectStudent(stud.id)}>
                  {stud?.name}
                </p>
              ))}
        </div>
        {userShow ? (
          <UserSearchModal
            members={mems ? mems : []}
            setQueryName={setQueryName}
            queryName={queryName}
            inputRef={inputRef}
            selectedUser={accountHolder}
            selectUser={selectUser}
          />
        ) : null}
        <MembershipForm created={createMembership.mutate} closed={closeModal} />
      </div>
    );
  }

  if (memberShow) {
    modalContent = (
      <SelectedMember
        membership={selectedMembership}
        deleteMembership={deleteMembership.mutate}
        edit={openMembershipEditModal}
      />
    );
  }

  if (memberEdit) {
    modalContent = (
      <div>
        <select
          onChange={e => {
            setMembershipType(e.target.value);
          }}
        >
          <option>youth</option>
          <option>adult</option>
          <option>family</option>
        </select>
        <Button
          btnType="Success"
          clicked={updateMembershipType.mutate}
          where={''}
        >
          Edit Type
        </Button>
      </div>
    );
  }

  if (isLoading) return <Spinner />;

  return (
    <div className={classes.container}>
      {isAlert && <Alert msg={message} alertType={type} />}
      <Modal show={show} modalClosed={closeModal}>
        {modalContent}
      </Modal>
      <div className={classes.filterContainer}>
        <SortBox
          filters={statusArr}
          searchParams={new URLSearchParams(searchParams)}
          click={updateSearchParams}
        />
        {/*<SortBox*/}
        {/*  filters={statusArr}*/}
        {/*  searchParams={new URLSearchParams(searchParams)}*/}
        {/*/>*/}
        {/*<SortBox filters={statusArr} searchParams={searchParams} />*/}
      </div>
      <div className={classes.memberships}>
        {memberships.map(mem => {
          return (
            <MembershipCard
              key={mem.id}
              mem={mem}
              selectMembership={selectMembership}
              deleteMembership={deleteMembership.mutate}
            />
          );
        })}
      </div>
      <div className={classes.membershipOptions}>
        <div>
          <Button btnType={'Success'} clicked={openCreateModal} where={''}>
            Create Membership
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MembershipDashboard;
