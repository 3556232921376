import classes from './TableRow.module.scss';
import React from 'react';
import { Member } from '../../../../containers/Members/Members';

type Props = {
  members: Member[];
  clicked: (id: string) => void;
  deleteMemberHandler: (id: string) => void;
  updateMemberHandler?: () => void;
};

const TableRow = (props: Props) => {
  let noticeText;

  return (
    <>
      {props.members.map(member => {
        if (!member.isVerified) {
          noticeText = 'Not Verified';
        } else noticeText = ' ';

        return (
          <div
            className={classes.row}
            key={member._id}
            onClick={() => props.clicked(member._id)}
          >
            <p className={classes.extra}>{noticeText}</p>
            <p className={classes.name}>{member.name}</p>
            <p className={classes.email}>{member.email}</p>
            <div className={classes.iconBox}>
              <svg
                className={classes.Icon + ' ' + classes.trash}
                onClick={() => props.deleteMemberHandler(member._id)}
              >
                <use xlinkHref={`/img/icons.svg#icon-trash`}></use>
              </svg>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TableRow;
