import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  LineController,
  BarController,
  Legend,
} from 'chart.js';
import { Chart, Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  LineController,
  BarController,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Month To Date',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const MonthToDate = (props: any) => {
  return (
    <>
      {props.barData && (
        <Chart type="bar" options={options} data={props.barData} />
      )}
    </>
  );
};

export default MonthToDate;
