import React, { useReducer, useEffect, useState, useRef } from 'react';

import styles from './Shop.module.scss';
import http from '../../hooks/http';
import useAlert from '../../hooks/alert';
import { formatPrice, wait } from '../../utils/utils';
import Modal from '../../components/UI/Modal/Modal';
import Alert from '../../components/UI/Alert/Alert';
import ItemSelection from './ItemSelection/ItemSelection';
import Checkout from './Checkout/Checkout';
import UserSearchModal from '../../components/UserSearchModal/UserSearchModal';
import Spinner from '../../components/UI/Spinner/Spinner';

export type DetailedProduct = {
  _id: string;
  availability: string;
  description: string;
  id: string;
  itemNo: string;
  name: string;
  photo: string;
  potentialProfit: number | null;
  price: number;
  priceId: string;
  quantity: number;
  sku: string;
  stripeId: string;
  size: string;
  color: string;
};

export type Product = {
  name: string;
  id: string;
  photo: string;
  results: number;
  varietys: DetailedProduct[];
};

type ProdAction = { type: 'SET'; products: Product[] };

type CartAction = { type: 'ADD'; product: DetailedProduct } | { type: 'RESET' };

const productReducer = (currentInventory: Product[], action: ProdAction) => {
  switch (action.type) {
    case 'SET':
      return action.products;
    default:
      throw new Error('SHOULD NOT HAPPEN!');
  }
};

const cartReducer = (currentCart: DetailedProduct[], action: CartAction) => {
  switch (action.type) {
    case 'ADD':
      return [...currentCart, action.product];
    case 'RESET':
      return [];
    default:
      throw new Error('SHOULD NOT HAPPEN');
  }
};

const Shop = () => {
  const [products, dispatch] = useReducer(productReducer, []);
  const [cart, dispatchCart] = useReducer(cartReducer, []);
  const [selectedProd, setSelectedProd] = useState<Product | undefined>();
  const [show, setShow] = useState(false);
  const [cartShow, setCartShow] = useState(false);
  const [userShow, setUserShow] = useState(false);
  const [isStripe, setIsStripe] = useState(false);
  const [sizeArr, setSizeArr] = useState<string[]>([]);
  const [colorArr, setColorArr] = useState<string[]>([]);
  const [discounts, setDiscounts] = useState<any>();
  const [discount, setDiscount] = useState<any>('');
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [members, setMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [preCartItem, setPreCartItem] = useState<
    DetailedProduct | null | undefined
  >();
  const { resData, sendRequest, isLoading, error, reqId } = http();
  const { setAlert, isAlert, message, type } = useAlert();
  const [queryName, setQueryName] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    sendRequest('products/shop', 'GET', undefined, undefined, 'set');
  }, [sendRequest]);

  useEffect(() => {
    if (queryName) setTouched(true);
    //
    if (!queryName && touched) return;

    if (!queryName && !touched) return;

    // closure happens here due to setTimeout, queryName will be was what typed in 500ms ago
    // while inputRef.current.value will be the current input
    (async () => {
      await wait(0.5);
      if (inputRef.current && queryName === inputRef.current.value) {
        await sendRequest(
          `users?like=${inputRef.current.value}`,
          'GET',
          null,
          null,
          'searched'
        );
        setQueryName('');
      }
    })();
  }, [sendRequest, inputRef, queryName, touched]);

  useEffect(() => {
    if (error) return setAlert('Error', error);
    if (isLoading) return;

    switch (reqId) {
      case 'set':
        return dispatch({ type: 'SET', products: resData.data.data });
      case 'searched':
        return setMembers(resData.data.data.users);
      case 'ORDER':
        setAlert('Success', 'Successfully Created Order!');
        setShow(false);
        setCartShow(false);
        return dispatchCart({ type: 'RESET' });
      case 'STRIPE':
        setAlert('Success', 'Successfully charged on Stripe!');
        setShow(false);
        setCartShow(false);
        return dispatchCart({ type: 'RESET' });
      case 'coupons':
        return setDiscounts(resData.data.data);
      default:
    }
  }, [isLoading, error, reqId, resData, setAlert]);

  useEffect(() => {
    if (!selectedProd) return;
    const mySet = new Set<string>();
    const colorSet = new Set<string>();
    selectedProd.varietys.forEach(el => {
      mySet.add(el.size);
      colorSet.add(el.color);
    });

    setColorArr([...colorSet]);
    setSizeArr([...mySet]);
  }, [selectedProd]);

  useEffect(() => {
    if (!size && !color) return;

    if (size && !color && selectedProd) {
      const colorOptions = selectedProd.varietys.filter(el => el.size === size);
      setColorArr(colorOptions.map(el => el.color));
    }

    if (size && color && selectedProd) {
      const [finalProd] = selectedProd.varietys.filter(
        el => el.size === size && el.color === color
      );

      setPreCartItem(finalProd);
    }
  }, [size, color, selectedProd?.varietys]);

  const toggleModal = () => {
    setSize('');
    setColor('');
    setPreCartItem(null);
    setShow(!show);
    setUserShow(false);
    setCartShow(false);
  };

  const showProduct = (id: string) => {
    setSelectedProd(products.find(el => el.varietys[0].id === id));
    setShow(true);
  };

  const addProductToCart = () => {
    if (preCartItem) dispatchCart({ type: 'ADD', product: preCartItem });
  };

  const showCartModal = () => {
    setCartShow(true);
    setShow(true);
  };

  const showUserSearchMod = () => {
    setUserShow(true);
    setShow(true);
  };

  const getCoupons = () => {
    sendRequest('orders/coupons', 'GET', undefined, undefined, 'coupons');
  };

  const finalizeOrder = (formData: any) => {
    const postObj = {
      ...formData,
      purchaser: selectedUser.id,
      cart: cart.map(el => el.id),
    };

    if (isStripe) {
      const stripePostObj: any = {
        ...formData,
        cart: cart.map(prod => prod.priceId),
      };

      stripePostObj.discount = discount.id;

      return sendRequest(
        `orders/for-user/${selectedUser.id}`,
        'POST',
        stripePostObj,
        undefined,
        'STRIPE'
      );
    }

    sendRequest('orders/cash/for-user', 'POST', postObj, undefined, 'ORDER');
  };

  const selectUser = (id: string) => {
    setSelectedUser(members.find((el: any) => el.id === id));
    setMembers([]);
  };

  const toggleStripe = () => {
    setIsStripe(!isStripe);
  };

  let modalContent = (
    <ItemSelection
      product={selectedProd}
      size={size}
      color={color}
      setSize={setSize}
      setColor={setColor}
      sizes={sizeArr}
      colors={colorArr}
      addProd={addProductToCart}
      specificProd={preCartItem}
    />
  );

  if (cartShow) {
    modalContent = (
      <Checkout
        cart={cart}
        order={finalizeOrder}
        toggleModal={toggleModal}
        user={selectedUser}
        discounts={discounts}
        discount={discount}
        toggleDiscounts={getCoupons}
        stripe={isStripe}
        setDiscount={setDiscount}
      />
    );
  }

  if (userShow) {
    modalContent = (
      <UserSearchModal
        members={members}
        setQueryName={setQueryName}
        queryName={queryName}
        inputRef={inputRef}
        selectedUser={selectedUser}
        selectUser={selectUser}
      />
    );
  }

  let cartBtn = (
    <div className={styles.cart} onClick={showCartModal}>
      <svg className={styles.icon}>
        <use xlinkHref={`/img/icons.svg#icon-shopping-cart`}></use>
      </svg>
      <p className={styles.num}>{cart.length}</p>
    </div>
  );

  if (cart.length < 1) {
    cartBtn = (
      <div className={[styles.cart, styles.noCart].join(' ')}>
        <svg className={styles.icon}>
          <use xlinkHref={`/img/icons.svg#icon-shopping-cart`}></use>
        </svg>
        <p className={styles.num}>{cart.length}</p>
      </div>
    );
  }
  const stripeStyles = [styles.stripe];

  if (isStripe) stripeStyles.push(styles.blue);

  if (isLoading && reqId === 'STRIPE') return <Spinner />;

  return (
    <div className={styles.shop}>
      {isAlert && <Alert msg={message} alertType={type} />}

      <Modal show={show} modalClosed={toggleModal} noPadding={false}>
        {modalContent}
      </Modal>
      <div className={styles.user} onClick={showUserSearchMod}>
        <svg className={styles.icon}>
          <use xlinkHref={`/img/icons.svg#icon-user`}></use>
        </svg>
        <p className={styles.num}>Purchaser: {selectedUser?.name}</p>
      </div>
      <div className={stripeStyles.join(' ')} onClick={toggleStripe}>
        <p>{isStripe ? 'Stripe' : 'Cash'}</p>
      </div>
      {cartBtn}
      {products?.map(prod => (
        <div
          className={styles.product}
          key={prod.varietys[0].id}
          onClick={event => showProduct(prod.varietys[0].id)}
        >
          <div>
            <img
              className={styles.image}
              src={`https://the-fight-lab-bucket.s3-us-west-2.amazonaws.com/img/products/${prod.photo}`}
              alt={`Image of ${prod.name}`}
            ></img>
          </div>
          <p>{prod.name}</p>
          <p>{formatPrice(prod.varietys[0].price)}</p>
        </div>
      ))}
    </div>
  );
};

export default Shop;
