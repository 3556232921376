import React, { useState, useEffect } from 'react';
import http from '../../hooks/http';

import styles from './BillingDashboard.module.scss';
import useAlert from '../../hooks/alert';
import Alert from '../../components/UI/Alert/Alert';
import BillingCard from '../../components/Billing/BillingCard/BillingCard';
import Modal from '../../components/UI/Modal/Modal';
import { formatPrice } from '../../utils/utils';
import { utcToFormattedDate } from '../../utils/dateUtils';

interface Plan {
  id: string;
  amount: number;
  billingDay: number;
  discount: number;
  name: string;
  status: string;
  paymentMethod: string;
  monthlyTotal: number;
  student: Student;
  nextDue: number;
  totalDiscount: number;
}

interface Student {
  name: string;
}

const BillingDashboard = () => {
  const [totalPredictedInc, setTotalPredictedInc] = useState<any>('');
  const [totalSubs, setTotalSubs] = useState(0);
  const [activeSubs, setActiveSubs] = useState(0);
  const [trialSubs, setTrialSubs] = useState(0);
  const [monthCash, setMonthCash] = useState<any>('');
  const [cashUsers, setCashUsers] = useState(0);
  const [totalInc, setTotalInc] = useState('');
  const [totalDiscounts, setTotalDiscounts] = useState('');
  const [subs, setSubs] = useState([]);
  const { resData, sendRequest, isLoading, error, reqId } = http();
  const { setAlert, isAlert, message, type } = useAlert();
  const [show, setShow] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [newCashUsers, setNewCashUsers] = useState([]);

  useEffect(() => {
    sendRequest('payments/subscriptions', 'GET', undefined, undefined, 'SET');
  }, []);

  // useEffect(() => {
  //   // if (newCashUsers.length > 0) return;
  //
  //   sendRequest('memberships', 'GET', undefined, undefined, 'quick');
  // }, []);

  useEffect(() => {
    if (error) return setAlert('Error', error);
    if (isLoading) return;

    switch (reqId) {
      case 'SET':
        setSubs(resData.data.plans);
        setActiveSubs(
          resData.data.plans.filter((plan: Plan) => plan.status === 'active')
            .length
        );
        setTrialSubs(
          resData.data.plans.filter((plan: Plan) => plan.status === 'trialing')
            .length
        );
        setTotalSubs(resData.data.results);
        setTotalPredictedInc(
          resData.data.plans
            .reduce((acc: number, plan: Plan) => {
              if (plan.status === 'cash') return acc;
              if (plan.discount) {
                return acc + (plan.amount - plan.discount);
              }
              return acc + plan.amount;
            }, 0)
            .toString()
        );
        setTotalDiscounts(
          resData.data.plans
            .reduce((acc: number, plan: Plan) => {
              return acc + plan.discount;
            }, 0)
            .toString()
        );

        // setCashUsers(
        //   resData.data.plans.filter(plan => plan.status === 'cash').length
        // );
        sendRequest(
          'memberships?status=active',
          'GET',
          undefined,
          undefined,
          'quick'
        );
        break;

      case 'quick':
        if (newCashUsers.length > 0) break;
        setNewCashUsers(
          resData.data.data.data.filter(
            (usr: any) => usr?.paymentMethod !== 'stripe'
          )
        );
        setCashUsers(
          resData.data.data.data.filter(
            (usr: any) => usr?.paymentMethod !== 'stripe'
          ).length
        );
        setMonthCash(
          resData.data.data.data
            .reduce((acc: number, plan: Plan) => {
              if (plan?.paymentMethod === 'stripe') return acc;
              return acc + plan.monthlyTotal;
            }, 0)
            .toString()
        );
        break;
      default:
    }
  }, [isLoading, error, setAlert, reqId, resData]);

  useEffect(() => {
    if (totalPredictedInc) {
      setTotalInc(1 * totalPredictedInc + monthCash * 1 + '');
    }
  }, [totalPredictedInc, monthCash]);

  const toggleModal = () => setShow(!show);

  let modalContent = null;

  const showMemberList = (header: any) => {
    console.log(header);
    setModalMode(header);
    setShow(true);
  };

  if (modalMode === 'Active Subscriptions') {
    modalContent = subs
      .filter((sub: Plan) => sub.status === 'active')
      .map((sub: Plan) => (
        <div className={styles.memberList} key={sub.id}>
          <p className={styles.name}>{sub.name}</p>
          <p className={styles.date}>{sub.billingDay}</p>
          <p className={styles.amount}>{formatPrice(sub.amount)}</p>
          <p className={styles.discount}>{sub.discount}</p>
        </div>
      ));
  }

  if (modalMode === 'Trial Subscriptions') {
    modalContent = subs
      .filter((sub: Plan) => sub.status === 'trialing')
      .map((sub: Plan) => (
        <div className={styles.memberList} key={sub.id}>
          <p className={styles.name}>{sub.name}</p>
          <p className={styles.date}>{sub.billingDay}</p>
          <p className={styles.amount}>{formatPrice(sub.amount)}</p>
          <p className={styles.discount}>{sub.discount}</p>
        </div>
      ));
  }

  if (modalMode === 'Cash Users') {
    modalContent = newCashUsers.map((sub: Plan) => (
      <div className={styles.memberList} key={sub.id}>
        <p className={styles.name}>{sub.student.name}</p>
        <p className={styles.date}>{utcToFormattedDate(sub.nextDue)}</p>
        <p className={styles.amount}>{formatPrice(sub.monthlyTotal)}</p>
        <p className={styles.discount}>{formatPrice(sub.totalDiscount)}</p>
      </div>
    ));
  }

  return (
    <div className={styles.billingContainer}>
      {isAlert && <Alert alertType={type} msg={message} />}
      <Modal show={show} modalClosed={toggleModal} noPadding>
        <div className={styles.modal}>
          <div className={styles.listHeader}>
            <p className={styles.name}>Name</p>
            <p className={styles.date}>Billing Date</p>
            <p className={styles.amount}>Amount</p>
            <p className={styles.discount}>Discount</p>
          </div>
          {modalContent}
        </div>
      </Modal>
      <BillingCard
        content={formatPrice(totalInc)}
        header={'Predicted Combined Income'}
      />
      <BillingCard
        content={formatPrice(totalPredictedInc)}
        header={'Predicted Stripe Income'}
      />
      <BillingCard
        content={formatPrice(monthCash)}
        header={'Monthly Cash Income'}
      />
      <BillingCard content={totalSubs} header={'Total Subscriptions'} />
      <BillingCard
        content={activeSubs}
        clicked={showMemberList}
        header={'Active Subscriptions'}
      />
      <BillingCard
        content={trialSubs}
        header={'Trial Subscriptions'}
        clicked={showMemberList}
      />
      <BillingCard
        content={cashUsers}
        header={'Cash Users'}
        clicked={showMemberList}
      />
      <BillingCard
        content={formatPrice(totalDiscounts)}
        header={'Total Discounts'}
      />
    </div>
  );
};

export default BillingDashboard;
