import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useCallback,
} from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import classes from './UserProfile.module.scss';
import UserDetails from './UserDetails/UserDetails';
import UserInfo from './UserInfo/UserInfo';
import Modal from '../../UI/Modal/Modal';
import NoteForm from '../../Forms/NoteForm/NoteForm';
import useHttp from '../../../hooks/http';
import useAlert from '../../../hooks/alert';
import Alert from '../../UI/Alert/Alert';
import { wait } from '../../../utils/utils';
import { AuthContext } from '../../../context/authContext';
import Spinner from '../../UI/Spinner/Spinner';
import StudentForm from '../../Forms/StudentForm/StudentForm';
import Subscription from '../../Subscription/Subscription';
import Students from '../../Students/Students';
import { Member, Note } from '../../../containers/Members/Members';

type Action =
  | { type: 'SET_MEMBER'; member: Member }
  | { type: 'ADD_NOTE'; note: Note }
  | { type: 'DELETE_NOTE'; id: string | null | undefined }
  | { type: 'UPDATE_NOTE'; note: Note };

const memberReducer = (curMember: Member, action: Action): any => {
  switch (action.type) {
    case 'SET_MEMBER':
      return action.member;
    case 'ADD_NOTE':
      return {
        ...curMember,
        notes: [action.note, ...curMember.notes],
      };
    case 'DELETE_NOTE':
      return {
        ...curMember,
        notes: curMember.notes.filter(note => note._id !== action.id),
      };
    case 'UPDATE_NOTE':
      return {
        ...curMember,
        notes: curMember.notes.map(note => {
          if (note._id === action.note._id) {
            return action.note;
          }
          return note;
        }),
      };
    default:
      console.error(new Error('MAJOR ERROR IN MEMBER REDUCER'));
  }
};

const UserProfile = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { sendRequest, resData, error, isLoading, reqExtra, reqId } = useHttp();
  const { isAlert, setAlert, type, message } = useAlert();
  const [member, dispatch] = useReducer(memberReducer, { notes: [] });
  const [show, setShow] = useState(false);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [nav, setNav] = useState('profile');
  const [isSubscribing, setIsSubscribing] = useState(false);
  const { user, me } = useContext(AuthContext);
  const [isStudent, setIsStudent] = useState(false);

  useEffect(() => {
    if (location.pathname === '/account') {
      if (user) {
        return dispatch({ type: 'SET_MEMBER', member: user });
      }
    }

    sendRequest(`users/${params.id}`, 'GET', null, null, 'set');
  }, [location.pathname, params.id, sendRequest, user]);

  useEffect(() => {
    if (error) return setAlert('Error', error);
    if (isLoading) return;

    switch (reqId) {
      case 'set':
        return dispatch({ type: 'SET_MEMBER', member: resData.data.data.data });
      case 'add':
        dispatch({ type: 'ADD_NOTE', note: resData.data.data.data });
        break;
      case 'addStudent':
        setAlert('Success', 'Successfully Added new Student');
        break;
      case 'update':
        dispatch({ type: 'UPDATE_NOTE', note: resData.data.data });
        break;
      case 'update_me':
        // wait a short amount of time before setting new member because uploading the user photo takes time
        // another option would be to refresh the page
        me();
        (async () => {
          await wait(1);
          dispatch({ type: 'SET_MEMBER', member: resData.data.data.data });
        })();
        return setAlert('Success', `Updated Account! Making Changes...`);

      case 'changeStatus':
        dispatch({ type: 'SET_MEMBER', member: resData.data.data });
        return setAlert(
          'Success',
          `Member status is now ${resData.data.data.isActive}`
        );
      case 'delete':
        return dispatch({ type: 'DELETE_NOTE', id: reqExtra });
      case 'paid':
        return setAlert('Success', 'Memberships Paid!');
      default:
    }

    (async () => {
      await wait(0.5);
      setShow(false);
      setSelectedNote(null);
    })();
  }, [resData, isLoading, error, reqId, reqExtra, setAlert, me]);

  const createStripeSession = async () => {
    setIsSubscribing(true);
  };

  const goBack = () => {
    // navigate.goBack();
  };

  const toggleModal = () => {
    setShow(!show);
    setSelectedNote(null);
  };

  const onAddNoteHandler = (event: React.ChangeEvent, note: any) => {
    setIsStudent(false);
    if (note) setSelectedNote(note);
    setShow(true);
  };

  const onAddStudentHandler = () => {
    setIsStudent(true);
    setShow(true);
  };

  const createStudent = (student: any) => {
    sendRequest(
      `users/${params.id}/students`,
      'POST',
      student,
      null,
      'addStudent'
    );
  };

  const onStatusChangeHandler = useCallback(() => {
    sendRequest(
      `users/${member.id}/changeStatus`,
      'PATCH',
      null,
      null,
      'changeStatus'
    );
  }, [member.id, sendRequest]);

  const createNote = (note: any) =>
    sendRequest(`users/${params.id}/notes`, 'POST', note, null, 'add');

  const updateNoteHandler = (formData: any) => {
    if (selectedNote) {
      sendRequest(
        `notes/${selectedNote._id}`,
        'PATCH',
        formData,
        null,
        'update'
      );
    }
  };

  const deleteNoteHandler = (id: any) => {
    if (!window.confirm('Delete this Note?')) return;
    sendRequest(`notes/${id}`, 'DELETE', null, id, 'delete');
  };

  const updateMe = (formData: any) =>
    sendRequest('users/updateMe', 'PATCH', formData, null, 'update_me');

  const countPaid = () => {
    if (member.memberships.length === 0)
      return setAlert('Error', 'There are no memberships!');

    const activeCashMemberships = member.memberships
      .filter(
        (el: any) => el.status === 'active' && el.paymentMethod === 'cash'
      )
      .map((el: any) => el.id);

    if (activeCashMemberships.length === 0)
      return setAlert('Error', 'There are no active cash memberships!');

    const formData: any = {
      purchaser: member.id,
      memberships: activeCashMemberships,
    };

    if (!window.confirm('Mark Memberships as paid?')) return;

    // @ts-ignore
    sendRequest(
      'orders/cash/for-user/memberships',
      'POST',
      formData,
      null,
      'paid'
    );
  };

  const formAlertHandler = useCallback(
    (type: any, message: any) => {
      setAlert(type, message);
    },
    [setAlert]
  );

  const navSwitchHandler = useCallback(
    (navName: React.SetStateAction<string>) => {
      setNav(navName);
    },
    []
  );

  if (isSubscribing) {
    if (isLoading) return <Spinner />;
    return <Subscription member={member} user={user} />;
  }

  let modalContent = (
    <NoteForm
      created={createNote}
      update={updateNoteHandler}
      closed={toggleModal}
      userId={params.id}
      note={selectedNote}
    />
  );

  if (isStudent) {
    modalContent = <StudentForm created={createStudent} closed={toggleModal} />;
  }

  let rightContent;

  switch (nav) {
    case 'profile':
      rightContent = (
        <UserInfo
          member={member}
          loading={isLoading}
          reqExtra={reqId}
          noteClick={onAddNoteHandler}
          deleteNote={deleteNoteHandler}
          account={location.pathname}
          alert={formAlertHandler}
          curUser={user}
          updateMe={updateMe}
          navClick={navSwitchHandler}
          subClick={createStripeSession}
          studentClick={onAddStudentHandler}
          toggleStatusClick={onStatusChangeHandler}
          paid={countPaid}
        />
      );
      break;
    case 'students':
      rightContent = (
        <Students navClick={navSwitchHandler} students={member.students} />
      );
  }

  return (
    <div className={classes.userProfile}>
      {isAlert && <Alert alertType={type} msg={message} />}
      {show && (
        <Modal show={show} modalClosed={toggleModal} noPadding={false}>
          {modalContent}
        </Modal>
      )}
      <UserDetails back={goBack} isActive={member.isActive} member={member} />
      {rightContent}
    </div>
  );
};

export default UserProfile;
