import React, { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Calendar from './containers/Calendar/Calendar.js';
import UserProfile from './components/Member/UserProfile/UserProfile';
import Members from './containers/Members/Members';
import AttendanceDash from './containers/AttendanceDash/AttendanceDash';
import BillingDashboard from './containers/BillingDashboard/BillingDashboard';
import { AuthContext } from './context/authContext';
import AuthForm from './components/Forms/AuthForm/AuthForm';
import Spinner from './components/UI/Spinner/Spinner';
import Inventory from './containers/Inventory/Inventory';
import Shop from './containers/Shop/Shop';
import Orders from './containers/Orders/Orders';
import MembershipDashboard from './containers/MembershipDashboard/MembershipDashboard';
import PLDashboard from './containers/PLDashboard/PLDashboard';
import AlertDashboard from './containers/AlertDashboard/AlertDashboard';
import MonthToDate from './containers/PLDashboard/MonthToDate/MonthToDate';
import ResetPassword from './components/Forms/ResetPassword/ResetPassword';
import ChangePassword from './components/Forms/ChangePassword/ChangePassword';
import LeadDashboard from './containers/LeadDashboard/LeadDashboard';

const App = () => {
  const { isAuth, user } = useContext(AuthContext);

  if (!user && !isAuth && !localStorage.getItem('token')) {
    return (
      <Layout>
        <Routes>
          <Route path="/auth" element={<AuthForm />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/change/:token" element={<ChangePassword />} />
          <Route path="/signup/:token" element={<AuthForm />} />
          <Route path="*" element={<Navigate to={'/auth'} />} />
        </Routes>
      </Layout>
    );
  }

  if (!user) {
    return <Spinner />;
  }

  // adding comment
  let routes = (
    <Routes>
      <Route path="/auth" element={<AuthForm />} />
      <Route path="/reset" element={<ResetPassword />} />
      <Route path="/signup/:token" element={<AuthForm />} />
      <Route path="*" element={<Navigate to={'/auth'} />} />
    </Routes>
  );

  if (user.role === 'user') {
    routes = (
      <Routes>
        <Route path="/account" element={<UserProfile />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/attendance" element={<AttendanceDash />} />
        <Route path="*" element={<Navigate to={'/account'} />} />
      </Routes>
    );
  }

  if (user.role !== 'user') {
    routes = (
      <Routes>
        <Route path="/account" element={<UserProfile />} />
        <Route path="/billing" element={<BillingDashboard />} />
        <Route path="/memberships" element={<MembershipDashboard />} />
        <Route path="/alerts" element={<AlertDashboard />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/businessIntelligence" element={<PLDashboard />} />
        <Route path="/members" element={<Members />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/test" element={<MonthToDate />} />
        <Route path="/member/:id" element={<UserProfile />} />
        <Route path="/crm" element={<LeadDashboard />} />
        <Route path="*" element={<Navigate to={'/calendar'} />} />
      </Routes>
    );
  }

  return <Layout>{routes}</Layout>;
};

export default App;
