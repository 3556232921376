import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../UI/Button/Button';
import { AuthContext } from '../../../context/authContext';

import classes from './AuthForm.module.scss';
import { objFormToArr, updateInputAndForm } from '../../../utils/utils';
import Input from '../../UI/Input/Input';

const AuthForm = React.memo(() => {
  const authContext = useContext(AuthContext);
  const params = useParams();

  const [loginForm, setLoginForm] = useState({
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'renato@gmail.com',
      },
      label: 'Email',
      value: '',
      validation: {
        required: true,
        minLength: 6,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter Password',
      },
      label: 'Password',
      value: '',
      validation: {
        required: true,
        minLength: 8,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
  });
  const [signupForm, setSignupForm] = useState({
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Minimum Length 8',
      },
      label: 'Enter Password',
      value: '',
      validation: {
        required: true,
        minLength: 8,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
    passwordConfirm: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Confirm Password...',
      },
      label: 'Confirm Password',
      value: '',
      validation: {
        required: true,
        minLength: 8,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      params.token ? signupForm : loginForm,
      inputIdentifier
    );
    setFormIsValid(valid);
    if (params.token) return setSignupForm(updatedForm);

    setLoginForm(updatedForm);
  };

  const submitHandler = e => {
    e.preventDefault();
    const {
      email: { value: emailValue },
      password: { value: passValue },
    } = loginForm;

    const {
      password: { value: signupPassValue },
      passwordConfirm: { value: passConfirmValue },
    } = signupForm;

    if (params.token)
      return authContext.signup(
        params.token,
        signupPassValue,
        passConfirmValue
      );

    console.log('sending dispatch handler for logging in');

    return authContext.login(emailValue, passValue);
  };

  let form = (
    <form onSubmit={submitHandler} aria-label="noteForm">
      {objFormToArr(params.token ? signupForm : loginForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button btnType="Success" disabled={!formIsValid}>
          {params.token ? 'Signup!' : 'Login'}
        </Button>
      </div>
    </form>
  );

  return (
    <div className={classes.formContainer}>
      <h1 className={classes.title}>
        {params.token ? 'Finish Signing Up!' : 'The Fight Lab App'}
      </h1>

      {form}
    </div>
  );
});

export default AuthForm;
