import React from 'react';
import { useMutation } from '@tanstack/react-query';
import Alert from '../../components/UI/Alert/Alert';
import AlertForm from '../../components/Forms/AlertForm/AlertForm';
import axios from '../../axios-lab';
import useAlert from '../../hooks/alert';

import classes from './AlertDashboard.module.scss';
import { wait } from '../../utils/utils';

const AlertDashboard = () => {
  const { setAlert, isAlert, message, type } = useAlert();

  const sendAlert = useMutation({
    mutationFn: (formData: any) => {
      const postObj = {
        message: formData.message,
      };

      return axios.post(`/message/${formData.membershipType}`, postObj);
    },
    onError: error => {
      setAlert('Error', error.message);
    },

    onSuccess: res => {
      setAlert('Success', `${res.data.count} alerts sent`);
      (async () => {
        await wait(1);
        window.location.reload();
      })();
    },
  });

  // setClearForm(false);

  return (
    <div className={classes.AlertBox}>
      {isAlert && <Alert msg={message} alertType={type} />}
      <AlertForm created={sendAlert.mutate} />
    </div>
  );
};

export default AlertDashboard;
