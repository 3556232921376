import React, { useEffect, useState } from 'react';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { updateInputAndForm, objFormToArr } from '../../../utils/utils';

const ProductForm = props => {
  const [productForm, setProductForm] = useState({
    name: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Enter Name...',
      },
      label: 'Name',
      value: '',
      validation: {
        required: true,
        minLength: 2,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
    price: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        placeholder: '0000',
      },
      label: 'Price',
      value: '',
      validation: {
        required: true,
        minLength: 3,
        maxLength: 6,
      },
      valid: false,
      touched: false,
    },
    cost: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        placeholder: '0000',
      },
      label: 'Cost',
      value: '',
      validation: {
        required: true,
        minLength: 3,
        maxLength: 6,
      },
      valid: false,
      touched: false,
    },
    description: {
      elementType: 'textarea',
      elementConfig: {
        type: 'input',
        placeholder: 'Enter Description...',
      },
      label: 'Description',
      value: '',
      validation: {
        required: true,
        minLength: 10,
        maxLength: 500,
      },
      valid: false,
      touched: false,
    },
    color: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Red, Green, Blue etc..',
      },
      label: 'Color',
      value: '',
      validation: {
        required: true,
        minLength: 2,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
    size: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'LG, MD, 16oz etc...',
      },
      label: 'Size',
      value: '',
      validation: {
        required: false,
        minLength: 2,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
    brand: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Ringside, Combat Sports, Fairtex etc...',
      },
      label: 'Brand',
      value: '',
      validation: {
        required: false,
        minLength: 2,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
    sku: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'XXX',
      },
      label: 'SKU',
      value: '',
      validation: {
        required: true,
        minLength: 2,
        maxLength: 60,
      },
      valid: false,
      touched: false,
    },
    quantity: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        placeholder: '00',
      },
      label: 'Quantity',
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
    photo: {
      elementType: 'input',
      elementConfig: {
        type: 'file',
        placeholder: '',
      },
      label: 'Choose Photo',
      value: '',
      validation: {},
      valid: false,
      touched: false,
      photo: true,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [image, setImage] = useState();
  const { product } = props;

  useEffect(() => {
    console.log('triggered');
    if (product) {
      console.log('start of if statement use effect');
      setProductForm(oldForm => {
        const newObj = {};
        for (const [key, value] of Object.entries(oldForm)) {
          console.log(key);
          console.log(value);
          newObj[key] = value;
          newObj[key].value = product[key];
        }
        newObj.photo = { ...oldForm.photo, value: '' };
        console.log(newObj);
        return newObj;
      });
    }

    console.log('end of use effect');
  }, [product]);

  const createOrEditProduct = e => {
    e.preventDefault();
    const form = new FormData();

    for (const [key, { value }] of Object.entries(productForm)) {
      if (key === 'photo' && image) {
        form.append(key, image);
        continue;
      }

      if (!value) continue;

      form.append(key, value);
    }

    if (product) return props.update(form);

    props.created(form);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    if (inputIdentifier === 'photo') {
      setImage(event.target.files[0]);
      return setFormIsValid(true);
    }

    const [valid, updatedForm] = updateInputAndForm(
      event,
      productForm,
      inputIdentifier
    );

    setProductForm(updatedForm);
    setFormIsValid(valid);
  };

  let form = (
    <form onSubmit={createOrEditProduct} aria-label="productForm">
      {objFormToArr(productForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
          photo={formElement.config.photo}
        />
      ))}
      {product && (
        <img
          style={{
            width: '20rem',
            height: '20rem',
          }}
          src={`https://the-fight-lab-bucket.s3-us-west-2.amazonaws.com/img/products/${product.photo}`}
        ></img>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button btnType="Success" disabled={!formIsValid}>
          {product ? 'Update Product' : 'Create Product'}
        </Button>
        <Button btnType="Danger" clicked={props.closed}>
          Cancel
        </Button>
      </div>
    </form>
  );

  return <div>{form}</div>;
};

export default ProductForm;
