import React, { useState, useEffect } from 'react';
import classes from '../Member/UserProfile/UserProfile.module.scss';
import Alert from '../UI/Alert/Alert';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Button from '../UI/Button/Button';
import useHttp from '../../hooks/http';
import useAlert from '../../hooks/alert';
import Spinner from '../UI/Spinner/Spinner';

const Subscription = props => {
  const stripe = useStripe();
  const elements = useElements();
  const { sendRequest, resData, error, isLoading, reqExtra, reqId } = useHttp();
  const { isAlert, setAlert, type, message } = useAlert();

  const [subStartDate, setSubStartDate] = useState(new Date());

  const [billingName, setBillingName] = useState('');
  const [stripePriceId, setStripePriceId] = useState('');
  const [plan1, setPlan1] = useState([classes.optionsBox]);
  const [plan2, setPlan2] = useState([classes.optionsBox]);
  const [trialDays, setTrialDays] = useState(0);

  useEffect(() => {
    if (error) return setAlert('Error', error);
    if (isLoading) return;

    switch (reqId) {
      case 'stripe':
        return setAlert(
          'Success',
          `Successfully started ${resData.data.subStatus} subscription`
        );
      case 'createCard':
        const cardElement = elements.getElement(CardElement);
        cardElement.clear();

        return setAlert('Success', `Successfully Saved Card`);
      default:
    }
  }, [resData, isLoading, error, reqId, reqExtra, setAlert]);

  useEffect(() => {
    if (!stripePriceId) {
      setPlan1([classes.optionsBox]);
      setPlan2([classes.optionsBox]);
    }
  }, [stripePriceId]);
  const onFutureChargeClick = () => {
    setPlan1([classes.optionsBox]);
    setPlan2([classes.optionsBox]);
    setStripePriceId('');
  };

  const onPlanClick = event => {
    setPlan1([classes.optionsBox]);
    setPlan2([classes.optionsBox]);

    if (
      event.target.innerText.includes('Adult') &&
      event.target.innerText.includes('Youth')
    )
      return;

    if (event.target.innerText.includes('Adult')) {
      setPlan1(prevState => {
        return [...prevState, classes.active];
      });
    }

    if (event.target.innerText.includes('Youth')) {
      setPlan2(prevState => {
        return [...prevState, classes.active];
      });
    }

    setStripePriceId(event.target.dataset.plan);
  };

  useEffect(() => {
    if (trialDays === 0) {
      return setSubStartDate(new Date(Date.now()));
    }

    if (trialDays > 0) {
      const currentDate = new Date();
      setSubStartDate(prevState => {
        return new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + +trialDays
        );
      });
    }
  }, [trialDays]);

  const createSubscription = async (stripeId, paymentMethodId, trialDays) => {
    const data = {
      stripeId,
      paymentMethodId,
      priceId: stripePriceId,
      trialDays,
    };

    await sendRequest(
      `payments/create-subscription`,
      'POST',
      data,
      null,
      'stripe'
    );
  };

  const createFuturePayment = (stripeId, paymentMethodId) => {
    const data = {
      stripeId,
      paymentMethodId,
    };

    sendRequest('payments/create-card', 'POST', data, null, 'createCard');
  };

  const createPaymentMethod = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: billingName,
      },
    });

    if (trialDays > 0) {
      return createSubscription(
        props.member.stripeId,
        paymentMethod.id,
        trialDays
      );
    }

    if (error) {
      setAlert('Error', error.message);
    } else {
      if (!stripePriceId) {
        return createFuturePayment(props.member.stripeId, paymentMethod.id);
      }

      await createSubscription(props.member.stripeId, paymentMethod.id);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={classes.full}>
      {isAlert && <Alert alertType={type} msg={message} />}
      <h2
        style={{
          marginLeft: '10rem',
          fontSize: '3rem',
        }}
      >
        {props.member.name}
      </h2>
      <form className={classes.stripeContainer}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '1.6rem',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        <div>
          <label style={{ fontSize: '2rem' }}>Name on Card</label>
          <input
            type={'text'}
            placeholder={'Name On Card'}
            value={billingName}
            onChange={event => {
              setBillingName(event.target.value);
            }}
            style={{
              marginLeft: '3rem',
              marginTop: '2rem',
            }}
          />
        </div>

        <div>
          <label style={{ fontSize: '2rem' }}>Trial Days</label>
          <input
            type={'number'}
            placeholder={'Trial Days'}
            value={trialDays}
            onChange={event => {
              setTrialDays(event.target.value);
            }}
            style={{
              width: '6rem',
              marginLeft: '3rem',
              marginTop: '2rem',
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            clicked={event => createPaymentMethod(event)}
            btnType={'Success'}
          >
            Join
          </Button>
        </div>
      </form>
      <p className={classes.selectedPlan}>
        Currently Selected Plan:
        {stripePriceId ? stripePriceId : 'saving default payment method'}
      </p>
      {/*Quick hack to limit instructor to only see save card info here*/}
      {props.user.role === 'instructor' ? null : (
        <p className={classes.selectedPlan}>
          Charge Date: {subStartDate.toLocaleDateString()}
        </p>
      )}
      <div className={classes.options} onClick={onPlanClick}>
        {props.user.role === 'instructor' ? null : (
          <>
            <div
              className={plan1.join(' ')}
              data-plan={'price_1IOC40IYLlrMHhAP2bnafSZv'}
            >
              <h3>Adult Membership</h3>
              <p>$80 / month</p>
            </div>
            <div
              className={plan2.join(' ')}
              data-plan={'price_1IOC3MIYLlrMHhAPIhi5QnyA'}
            >
              <h3>Youth Membership</h3>
              <p>$60 / month</p>
            </div>
          </>
        )}
      </div>
      <div className={classes.save} onClick={onFutureChargeClick}>
        <h3>Save Card For Future Charge</h3>
      </div>
    </div>
  );
};

export default Subscription;
