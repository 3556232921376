import React from 'react';
import { formatPrice } from '../../../utils/utils';
import styles from './OrderDetails.module.scss';
import Orders, { Order, Product } from '../Orders';

type Props = {
  order: any;
};

const OrderDetails = (props: Props) => {
  return (
    <div className={styles.order}>
      <div className={styles.leftArea}>
        <div className={styles.infoGroup}>
          <p className={styles.header}>Order #</p>
          <p className={styles.underHeader}>{props?.order?.orderNo}</p>
        </div>
        <div className={styles.infoGroup}>
          <p className={styles.header}>Total</p>
          <p className={styles.underHeader}>
            {formatPrice(props?.order?.total, true)}
          </p>
        </div>
        <div className={styles.infoGroup}>
          <p className={styles.header}>status</p>
          <p className={styles.underHeader}>{props?.order?.status}</p>
        </div>
      </div>
      <div className={styles.productBox}>
        {props?.order?.products?.map((prod: Product, i: number) => (
          <div className={styles.product} key={prod.id + i}>
            <p className={styles.name}>
              <span>{i + 1}. </span>
              {prod.name}
            </p>
            <div className={styles.innerBox}>
              <p className={styles.size}>{prod.size}</p>
              <p className={styles.color}>{prod.color}</p>
              <p className={styles.price}>{formatPrice(prod.price)}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.rightArea}>
        <div className={styles.infoGroup}>
          <p className={styles.header}>Order Placed</p>
          <p className={styles.underHeader}>
            {new Date(props?.order?.datePurchased).toLocaleDateString()}
          </p>
        </div>
        <div className={styles.infoGroup}>
          <p className={styles.header}>Purchaser</p>
          <p className={styles.underHeader}>{props?.order?.purchaser?.name}</p>
        </div>
      </div>
      <div className={styles.noteBox}>
        <p className={styles.note}>Note</p>
        <p>{props?.order?.orderNote}</p>
      </div>
      <div>
        <p className={styles.note}>Payment Type</p>
        <p>{props?.order?.cash ? 'Cash' : 'Stripe'}</p>
      </div>
    </div>
  );
};

export default OrderDetails;
