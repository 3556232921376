import React from 'react';
import classes from './AttendanceSearch.module.scss';

const AttendanceSearch = props => {
  let iconClasses = classes.icon;
  let inputClasses = classes.input;

  if (props.noList) {
    iconClasses = classes.iconNoList;
  }

  if (props.checkout) {
    iconClasses = classes.checkout;
    inputClasses = classes.inputCheckout;
  }

  return (
    <>
      <svg className={iconClasses}>
        <use xlinkHref={`/img/icons.svg#icon-search`} />
      </svg>
      <input
        placeholder={'Search'}
        className={inputClasses}
        type="text"
        ref={props.inputRef}
        value={props.name}
        onChange={event => props.change(event.target.value)}
        onBlur={props.focus}
      />
      {props.checkout ? (
        <p>
          User: <span>{props.selectedUser?.name}</span>{' '}
        </p>
      ) : null}
      {props.noList ? null : (
        <ul className={classes.searchedUsers}>
          {props.users.map(user => (
            <li
              className={classes.searchedUser}
              key={user._id}
              onClick={() => props.addUser(user)}
            >
              {user.name}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default AttendanceSearch;
