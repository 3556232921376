import React from 'react';
import classes from './AdminBox.module.scss';

type User = {
  role: string;
  photo: string;
  name: string;
};

type Props = {
  user: User | null;
};

const AdminBox = ({ user }: Props) => {
  let roleText;
  if (user?.role === 'user') roleText = 'Member';
  else roleText = user?.role;

  return (
    <div className={classes.adminBox}>
      <div className={classes.imageBlock}>
        <img
          className={classes.image}
          alt={'Current User'}
          src={`https://the-fight-lab-bucket.s3-us-west-2.amazonaws.com/img/users/${user?.photo}
          `}
        />
      </div>
      <h3 className={classes.name}>{user?.name}</h3>
      <p className={classes.role}>{roleText}</p>
    </div>
  );
};

export default AdminBox;
