import React, { useState, useEffect, useReducer } from 'react';

import styles from './Inventory.module.scss';
import BillingCard from '../../components/Billing/BillingCard/BillingCard';
import InventoryList from './InventoryList/InventoryList';
import http from '../../hooks/http';
import useAlert from '../../hooks/alert';
import Alert from '../../components/UI/Alert/Alert';
import { formatPrice, wait } from '../../utils/utils';
import Button from '../../components/UI/Button/Button';

const inventoryReducer = (currentInventory, action) => {
  switch (action.type) {
    case 'SET':
      return action.products;
    case 'ADD':
      return [...currentInventory, action.product];
    case 'DELETE':
      return currentInventory.filter(el => el.id !== action.id);
    // UPDATE is busted due to organized thing on dumb api call i made
    case 'UPDATE':
      return currentInventory.map(el => {
        if (el._id === action.updatedProduct._id) {
          return action.updatedProduct;
        }
        return el;
      });
    default:
      throw new Error('SHOULD NOT HAPPEN!');
  }
};

const Inventory = () => {
  const [totalProds, setTotalProds] = useState(0);
  const [totalValue, setTotalValue] = useState('000000');
  const [inventory, dispatch] = useReducer(inventoryReducer, []);
  const [cog, setCog] = useState(0);
  const [potentialProfit, setPotentialProfit] = useState(0);
  const [show, setShow] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const { resData, sendRequest, isLoading, error, reqId } = http();
  const { setAlert, isAlert, message, type } = useAlert();

  useEffect(() => {
    sendRequest('products/organized', 'GET', undefined, undefined, 'set');
  }, [sendRequest]);

  useEffect(() => {
    if (error) return setAlert('Error', error);
    if (isLoading) return;

    switch (reqId) {
      case 'set':
        setTotalValue(resData.data.value);
        setTotalProds(resData.data.data.length);
        setCog(resData.data.cog);
        setPotentialProfit(resData.data.potentialProfit);
        return dispatch({ type: 'SET', products: resData.data.data });
      case 'add':
        setShow(false);
        setAlert('Success', 'Successfully created new Product!');
        setIsForm(false);
        // return dispatch({ type: 'ADD', product: resData.data.data.data });
        (async () => {
          await wait(1);
          window.location.reload();
        })();
      case 'update':
        setShow(false);
        setAlert('Success', 'Successfully updated your Product!');

        (async () => {
          await wait(1);
          window.location.reload();
        })();
      // return dispatch({
      //   type: 'UPDATE',
      //   updatedProduct: resData.data.data.data,
      // });
      case 'delete':
        setShow(false);
        setAlert('Success', 'Successfully deleted product!');
        return dispatch({ type: 'DELETE', id: selectedProduct.id });
      case 'searched':
        return dispatch({ type: 'SET', members: resData.data.data.users });
      default:
    }
  }, [isLoading, error, reqId, resData, setAlert]);

  const toggleModal = id => {
    setSelectedProduct(
      ...inventory
        .map(rel => {
          return rel.varietys.find(el => el.id === id);
        })
        .filter(el => el !== undefined)
    );
    setShow(!show);
    setUpdate(false);
    setIsForm(false);
  };

  const showForm = () => {
    setShow(true);
    setIsForm(true);
  };

  const createProduct = product => {
    sendRequest('products', 'POST', product, undefined, 'add');
  };

  const updateProduct = product => {
    sendRequest(
      `products/${selectedProduct.id}`,
      'PATCH',
      product,
      undefined,
      'update'
    );
  };

  const viewUpdateModal = () => {
    setUpdate(true);
  };

  const deleteProduct = () => {
    if (!window.confirm(`Delete product "${selectedProduct.name}"?`)) return;

    sendRequest(
      `products/${selectedProduct.id}`,
      'DELETE',
      undefined,
      undefined,
      'delete'
    );
  };

  return (
    <div className={styles.Inventory}>
      {isAlert && <Alert msg={message} alertType={type} />}
      <BillingCard content={totalProds} header={'# Of Products'} />
      <BillingCard content={formatPrice(cog)} header={'Cost of Goods'} />
      <BillingCard
        content={formatPrice(totalValue)}
        header={'Inventory Value'}
      />
      <BillingCard
        content={formatPrice(potentialProfit)}
        header={'Profit Potential'}
      />
      <InventoryList
        products={inventory}
        toggleModal={toggleModal}
        show={show}
        product={selectedProduct}
        form={isForm}
        createProduct={createProduct}
        updateProduct={updateProduct}
        viewUpdate={viewUpdateModal}
        update={update}
        delete={deleteProduct}
      />
      <div className={styles.btn}>
        <Button btnType={'Success'} clicked={showForm}>
          Create Product
        </Button>
      </div>
    </div>
  );
};

export default Inventory;
