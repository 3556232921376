import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Doughnut, Chart } from 'react-chartjs-2';
import { useQuery } from '@tanstack/react-query';
import axios from '../../axios-lab';
import { chartColors } from './ChartColors';
import classes from './PLDashboard.module.scss';
import MonthToDate from './MonthToDate/MonthToDate';
import { monthTDHelper } from '../../utils/utils';
import Filter from './Filter/Filter';
import { doughOptions, options } from '../../utils/plotOptions';
import ProfitLossBox from './ProfitLossBox/ProfitLossBox';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const PlDashboard = () => {
  const [monthNo, setMonthNo] = useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [paymentType, setPaymentType] = useState<any>('both');
  const [stackedBarData, setStackedBarData] = useState<any>();

  type Profit = {
    _id: string;
    costOfGoods: number;
    count: number;
    membershipSales: number;
    productSales: number;
    profit: number;
  };

  const getProfits = async (): Promise<Profit[]> => {
    const { data } = await axios.get(
      `orders/profit/${year}/${monthNo}/${paymentType}`
    );

    return data.data;
  };

  const {
    data: profits = [],
    isError,
    isPending,
  } = useQuery({
    queryKey: ['profits', year, monthNo, paymentType],
    queryFn: getProfits,
  });

  console.log(profits);

  const { data: curMonthToDate, error: curMTDError } = useQuery({
    queryKey: ['curMonthToDate', year, monthNo],
    queryFn: async () => {
      const { data } = await axios.get(
        `orders/profit/monthToDate/${year}/${monthNo}`
      );
      return data.data;
    },
  });

  const { data: lastMonthToDate } = useQuery({
    queryKey: ['lastMonthToDate', year, monthNo],
    queryFn: async () => {
      const { data } = await axios.get(
        `orders/profit/monthToDate/${year}/${monthNo - 1}`
      );
      return data.data;
    },
  });

  const [inventoryLabels, setInventoryLabels] = useState<any>([]);
  const [invDataPoints, setInvDataPoints] = useState<any>([]);
  const [allDataPoints, setAllDataPoints] = useState<any>([]);
  const [allLabels, setAllLabels] = useState<any>([]);
  const [dataP, setDataP] = useState<any>();

  useEffect(() => {
    if (Array.isArray(profits) && profits.length > 0) {
      setInventoryLabels(
        profits.flatMap((el: any) =>
          el._id === 'Memberships' || el._id === 'Private Lessons'
            ? []
            : [el._id]
        )
      );
      setInvDataPoints(
        profits.flatMap(el =>
          el._id === 'Memberships' || el._id === 'Private Lessons'
            ? []
            : el.profit / 100
        )
      );
      setAllLabels(profits.map((el: any) => el._id));
      setAllDataPoints(
        profits.map((el: any) => {
          if (el._id === 'Memberships') {
            return el.membershipSales / 100;
          }
          return el.profit / 100;
        })
      );
    }
  }, [profits, paymentType]);

  useEffect(() => {
    if (invDataPoints.length > 0) {
      setDataP({
        labels: inventoryLabels,
        datasets: [
          {
            label: 'Dataset1',
            data: invDataPoints,
            backgroundColor: 'rgba(255,129,51, .7)',
          },
        ],
      });
    }
  }, [invDataPoints, inventoryLabels]);

  useEffect(() => {
    if (!lastMonthToDate) return;

    if (allDataPoints.length > 0 && lastMonthToDate.length > 0) {
      const myArr = monthTDHelper(profits);
      const lastMonthCleanedArr = monthTDHelper(lastMonthToDate);

      const plotData: any = myArr.map(el => {
        switch (el._id) {
          case 'Memberships':
            return {
              label: el._id,
              data: [
                lastMonthToDate.find((x: any) => x._id === 'Memberships')
                  .membershipSales / 100,
                el.membershipSales / 100,
              ],
              backgroundColor: '#ff8133',
            };
          case 'Private Lessons':
            const lastMonthPrivates = lastMonthCleanedArr.find(
              x => x._id === 'Private Lessons'
            );

            if (!lastMonthPrivates) {
              return {
                label: el._id,
                data: [0, el.profit / 100],
                backgroundColor: '#aa55e5',
              };
            }

            return {
              label: el._id,
              data: [lastMonthPrivates.profit / 100 ?? 0, el.profit / 100],
              backgroundColor: '#aa55e5',
            };
          case 'Inventory':
            return {
              label: el._id,
              data: [
                lastMonthCleanedArr.find(x => x._id === 'Inventory').profit /
                  100,
                el.profit / 100,
              ],
              backgroundColor: '#635bff',
            };
        }
      });

      const lastTotal = lastMonthCleanedArr.reduce((acc, cur) => {
        if (cur.profit) {
          return acc + cur.profit / 100;
        }
        return acc + cur.membershipSales / 100;
      }, 0);
      const curTotal = myArr.reduce((acc, cur) => {
        if (cur.profit) {
          return acc + cur.profit / 100;
        }
        return acc + cur.membershipSales / 100;
      }, 0);

      plotData.unshift({
        type: 'line',
        pointStyle: 'cross',
        pointRadius: 15,
        pointRotation: 45,
        hitRadius: 4,
        label: 'Totals',
        data: [lastTotal, curTotal],
        borderColor: '#5dbfe8',
        backgroundColor: '#e56a29',
        borderWidth: 4,
        fill: false,
        pointBackgroundColor: '#27aae1',
        pointBorderColor: '#27aae1',
        hoverRadius: 20,
      });

      setStackedBarData({
        labels: ['May', 'June'],
        datasets: plotData,
      });
    }
  }, [allDataPoints.length]);

  const onPayClick = (payType: string) => {
    switch (payType) {
      case 'stripe':
        return setPaymentType('stripe');
      case 'cash':
        return setPaymentType('cash');
      case 'both':
        return setPaymentType('both');
    }
  };

  let bar = null;
  if (dataP) {
    // @ts-ignore
    bar = <Bar datasetIdKey="id" options={options} data={dataP} />;
  }

  console.log(profits);

  return (
    <>
      <div className={classes.chartContainer}>
        <div className={classes.titleBox}>
          <p className={classes.year}>{year}</p>
          <p className={classes.month}>
            {new Date(year, monthNo - 1).toLocaleString('default', {
              month: 'long',
            })}
          </p>
        </div>
        <Filter
          onPayClick={onPayClick}
          year={year}
          setYear={setYear}
          monthNo={monthNo}
          setMonthNo={setMonthNo}
        />

        <div className={classes.bar}>{bar}</div>
        <div className={classes.doughnut}>
          <p className={classes.total}>
            {'$' +
              allDataPoints
                .reduce((el: any, a: any) => el + a, 0)
                .toLocaleString()}
          </p>
          <Doughnut
            // @ts-ignore
            option={doughOptions}
            data={{
              labels: allLabels,
              datasets: [
                {
                  data: allDataPoints,
                  backgroundColor: chartColors,
                },
              ],
            }}
          ></Doughnut>
        </div>
        <ProfitLossBox profits={profits} />
        <div className={classes.stackedBar}>
          <MonthToDate barData={stackedBarData} />
        </div>
      </div>
    </>
  );
};

export default PlDashboard;
