import React from 'react';
import UserProfileNav from '../Member/UserProfile/UserProfileNav/UserProfileNav';

const Students = props => {
  return (
    <div>
      <UserProfileNav navClick={props.navClick} />
      {props.students.map(student => (
        <p key={student.id}>{student.name}</p>
      ))}
    </div>
  );
};

export default Students;
