export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  return isValid;
};

export const updateInputAndForm = (event, form, inputIdentifier) => {
  const updatedFormElement = updateObject(form[inputIdentifier], {
    value: event.target.value,
    valid: checkValidity(event.target.value, form[inputIdentifier].validation),
    touched: true,
  });

  const updatedForm = updateObject(form, {
    [inputIdentifier]: updatedFormElement,
  });

  let formIsValid = true;
  // eslint-disable-next-line no-unused-vars
  for (let inputIdentifiers in updatedForm) {
    formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
  }

  return [formIsValid, updatedForm];
};

export const objFormToArr = form => {
  const formElementsArray = [];
  for (const [key, value] of Object.entries(form)) {
    formElementsArray.push({
      id: key,
      config: value,
    });
  }
  return formElementsArray;
};

export const deleteReducer = () => {};

export const wait = seconds => {
  return new Promise(function (resolve) {
    setTimeout(resolve, seconds * 1000);
  });
};

export const calendarColorPicker = className => {
  const randomColors = ['#ACC18A', '#FEC9F1', '#5FAD56'];

  switch (className) {
    case 'Kickboxing':
      return '#EF6461';
    case 'Gi BJJ':
      return '#5E747F';
    case 'No-Gi BJJ':
      return '#FAA300';
    case 'MMA':
      return '#ff8133';
    case 'Kids Class':
      return '#dc3030';
    default:
      return randomColors[Math.floor(Math.random() * 3 + 1) - 1];
  }
};

export const formatPrice = (price, promo) => {
  price += '';

  if (price === '0' && promo) {
    return 'PROMO';
  }

  if (price === '0') return '$0.00';

  return `$${price.substring(0, price.length - 2)}.${price.substring(
    price.length - 2
  )}`;
};

export const monthTDHelper = arr => {
  const inventoryProfit = arr.reduce((acc, cur) => {
    if (cur.membershipSales) {
      return acc;
    }

    if (cur._id === 'Private Lessons') {
      return acc;
    }

    return acc + cur.profit;
  }, 0);

  const cleanedArr = arr.filter(
    profit => profit._id === 'Memberships' || profit._id === 'Private Lessons'
  );

  return [
    ...cleanedArr,
    {
      _id: 'Inventory',
      costOfGoods: 0,
      membershipSales: 0,
      profit: inventoryProfit,
    },
  ];
};
