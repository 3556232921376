export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top as const',
    },
    title: {
      display: true,
      text: 'Inventory profit for Month',
    },
  },
};
export const doughOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top as const',
    },
    title: {
      display: true,
      text: 'Profit for Month',
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  },
};
