import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useAlert from '../../hooks/alert';
import useHttp from '../../hooks/http';
import styles from './Members.module.scss';
import Button from '../../components/UI/Button/Button';
import MemberTable from '../../components/Member/MemberTable/MemberTable';
import Alert from '../../components/UI/Alert/Alert';
import MemberForm from '../../components/Forms/MemberForm/MemberForm';
import Modal from '../../components/UI/Modal/Modal';
import AttendanceSearch from '../../components/Attendance/AttendanceSearch/AttendanceSearch';
import { wait } from '../../utils/utils';
import BillingCard from '../../components/Billing/BillingCard/BillingCard';

export type Note = {
  _id: string;
  note: string;
  createdBy: string;
};

export type Member = {
  id: string;
  _id: string;
  isActive: boolean;
  isVerified: boolean;
  name: string;
  email: string;
  notes: Note[];
};

type Action =
  | { type: 'SET'; members: Member[] }
  | { type: 'ADD'; member: Member }
  | { type: 'DELETE'; id: string | null | undefined };

const membersReducer = (currentMembers: Member[], action: Action) => {
  switch (action.type) {
    case 'SET':
      return action.members;
    case 'ADD':
      return [action.member, ...currentMembers];
    case 'DELETE':
      return currentMembers.filter(el => el.id !== action.id);
    default:
      throw new Error('SHOULD NOT HAPPEN!');
  }
};

const Members = () => {
  const { setAlert, isAlert, message, type } = useAlert();
  const { error, sendRequest, resData, isLoading, reqExtra, reqId } = useHttp();
  const [members, dispatch] = useReducer(membersReducer, []);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [queryName, setQueryName] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (error) return setAlert('Error', error);
    if (isLoading) return;

    switch (reqId) {
      case 'set':
        return dispatch({ type: 'SET', members: resData.data.data.users });
      case 'add':
        setShow(false);
        setAlert('Success', 'Successfully added new member!');
        return dispatch({ type: 'ADD', member: resData.data.data });
      case 'searched':
        return dispatch({ type: 'SET', members: resData.data.data.users });
      case 'delete':
        return dispatch({ type: 'DELETE', id: reqExtra });
      default:
    }
  }, [resData, isLoading, error, reqExtra, reqId, setAlert]);

  useEffect(() => {
    if (queryName) setTouched(true);

    if (!queryName && touched) return;

    if (!queryName && !touched) {
      sendRequest('users?isActive=true', 'GET', null, null, 'set');
      return;
    }

    // closure happens here due to setTimeout, queryName will be was what typed in 500ms ago
    // while inputRef.current.value will be the current input
    (async () => {
      await wait(0.5);
      if (inputRef.current && queryName === inputRef.current.value) {
        await sendRequest(
          `users?like=${inputRef.current.value}`,
          'GET',
          null,
          null,
          'searched'
        );
        setQueryName('');
      }
    })();
  }, [sendRequest, inputRef, queryName, touched]);

  const toggleModal = () => setShow(!show);

  const removeFocus = () => {
    setTouched(false);
  };

  const deleteMemberHandler = (id: string) => {
    if (!window.confirm('Delete this User?')) return;
    sendRequest(`users/${id}`, 'DELETE', null, id, 'delete');
  };

  const updateMemberHandler = () => {
    toggleModal();
  };

  const showMemberForm = () => setShow(true);

  const createMember = (formData: any) => {
    console.log(formData);
    sendRequest('/users/signup', 'POST', formData, null, 'add');
  };

  const onUserClickHandler = (id: string) => navigate(`/member/${id}`);

  return (
    <div className={styles.membersContainer}>
      {isAlert && <Alert alertType={type} msg={message} />}
      <Modal show={show} modalClosed={toggleModal} noPadding={false}>
        <MemberForm created={createMember} closed={toggleModal} />
      </Modal>
      <BillingCard
        header={'Active Accounts'}
        content={members.filter(mem => mem.isActive).length}
        span2
      />
      <div className={styles.buttonContainer}>
        <Button
          btnType={'Success'}
          clicked={showMemberForm}
          disabled={false}
          where={''}
        >
          Add Member
        </Button>
      </div>
      <div className={styles.attendanceSearchBox}>
        <AttendanceSearch
          users={members}
          change={setQueryName}
          name={queryName}
          inputRef={inputRef}
          focus={removeFocus}
          noList
        />
      </div>

      <MemberTable
        members={members}
        clicked={onUserClickHandler}
        updateMemberHandler={updateMemberHandler}
        deleteMemberHandler={deleteMemberHandler}
      />
    </div>
  );
};

export default Members;
