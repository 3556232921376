import React, { useEffect, useState } from 'react';
import Input from '../../../components/UI/Input/Input';

import styles from './ItemSelection.module.scss';
import { formatPrice } from '../../../utils/utils';
import Button from '../../../components/UI/Button/Button';
import { DetailedProduct, Product } from '../Shop';

// <ItemSelection

//   setSize={setSize}
//   setColor={setColor}

//   addProd={addProductToCart}

type Props = {
  product: Product | undefined;
  size: string;
  color: string;
  sizes: string[];
  colors: string[];
  setSize: (newSize: string) => void;
  setColor: (newSize: string) => void;
  addProd: () => void;
  specificProd: DetailedProduct | null | undefined;
};

const ItemSelection = (props: Props) => {
  const [colOptions, setColOptions] = useState<any>([]);
  const [sizeOptions, setSizeOptions] = useState<any>([]);
  const [variableStyle, setVariableStyle] = useState([styles.dynamic]);

  useEffect(() => {
    const colorOptions = props.colors.map(col => {
      return { value: col, displayValue: col };
    });

    colorOptions.unshift({ value: '', displayValue: '- Please Choose' });
    setColOptions(colorOptions);
  }, [props.colors]);

  useEffect(() => {
    const sizeOptions = props.sizes.map(size => {
      return { value: size, displayValue: size };
    });

    sizeOptions.unshift({ value: '', displayValue: '- Please Choose' });
    setSizeOptions(sizeOptions);
  }, [props.sizes]);

  useEffect(() => {
    if (props.specificProd) {
      setVariableStyle([styles.dynamic, styles.active]);
    } else {
      setVariableStyle([styles.dynamic, styles.disabled]);
    }
  }, [props.specificProd]);

  return (
    <div className={styles.box}>
      <p className={styles.name}>{props.product?.name}</p>
      <p className={styles.price}>
        {formatPrice(props.product?.varietys[0].price)}
      </p>
      <p>{props.product?.varietys[0].description}</p>
      <p className={variableStyle.join(' ')}>
        In Stock: {props.specificProd ? props.specificProd.quantity : null}
      </p>
      <p className={variableStyle.join(' ')}>
        SKU - {props.specificProd ? props.specificProd.sku : null}
      </p>
      <div className={styles.form}>
        <div className={styles.selectBoxes}>
          <Input
            elementType={'select'}
            value={props.size}
            elementConfig={{
              options: sizeOptions,
            }}
            changed={(event: any) => props.setSize(event.target.value)}
            label={'Size'}
          />
          <Input
            elementType={'select'}
            value={props.color}
            elementConfig={{
              options: colOptions,
            }}
            changed={event => props.setColor(event.target.value)}
            label={'Color'}
          />
        </div>

        <Button
          disabled={props.specificProd?.quantity === 0}
          clicked={props.addProd}
          btnType={'Success'}
          where={''}
        >
          Add to Cart
        </Button>
      </div>
    </div>
  );
};

export default ItemSelection;
