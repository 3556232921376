import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from 'react';

import useAlert from '../../hooks/alert';
import useHttp from '../../hooks/http';
import styles from './Calendar.module.scss';
import CalendarHeader from '../../components/Calendar/CalendarHeader/CalendarHeader';
import MonthlyCalendar from '../../components/Calendar/MonthlyCalendar/MonthlyCalendar';
import Modal from '../../components/UI/Modal/Modal';
import ExpandedDate from '../../components/Calendar/ExpandedDate/ExpandedDate';
import ClassForm from '../../components/Forms/ClassForm/ClassForm';
import Alert from '../../components/UI/Alert/Alert';
import Attendance from '../Attendance/Attendance';
import { wait } from '../../utils/utils';
import { AuthContext } from '../../context/authContext';
import Spinner from '../../components/UI/Spinner/Spinner';
import {
  buildBiWeeklyCalendar,
  buildMonthlyCalendar,
} from '../../utils/dateUtils';

const classReducer = (currentClasses, action) => {
  switch (action.type) {
    case 'SET':
      return action.classes;
    case 'ADD':
      return [...currentClasses, action.class];
    case 'ADD_MULTIPLE':
      return [...currentClasses, ...action.classes];
    case 'DELETE':
      return currentClasses.filter(el => el._id !== action.id);
    case 'UPDATE':
      return currentClasses.map(el => {
        if (el._id === action.updatedClass._id) {
          return action.updatedClass;
        }
        return el;
      });
    default:
      throw new Error('SHOULD NOT HAPPEN!');
  }
};

const Calendar = () => {
  const { setAlert, isAlert, message, type } = useAlert();
  const { isLoading, sendRequest, resData, error, reqExtra, reqId } = useHttp();
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(new Date());
  const [daysInMonth, setDaysInMonth] = useState(null);
  const [daysArray, setDaysArray] = useState([]);
  const [classes, dispatch] = useReducer(classReducer, []);
  const [selectedDay, setSelectedDay] = useState();
  const [showForm, showCreateForm] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [isBiWeekly, setIsByWeekly] = useState(false);
  const [isAttendance, setIsAttendance] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    sendRequest(`class/month/${date.getMonth() + 1}`, 'GET', null, null, 'set');
  }, [date, sendRequest]);

  useEffect(() => {
    setDaysInMonth(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    );
  }, [date]);

  useEffect(() => {
    if (isBiWeekly) return setDaysArray(buildBiWeeklyCalendar(date, classes));

    setDaysArray(buildMonthlyCalendar(date, classes));
  }, [classes, date, daysInMonth, isBiWeekly]);

  useEffect(() => {
    if (error) return setAlert('Error', error);
    if (isLoading) return;

    if (reqId) {
      setTimeout(() => {
        setShow(false);
        showCreateForm(false);
      }, 500);
    }

    switch (reqId) {
      case 'set':
        return dispatch({ type: 'SET', classes: resData.data.data.classes });
      case 'delete':
        dispatch({ type: 'DELETE', id: reqExtra });
        return setAlert('Success', `Successfully deleted class ${reqExtra}!`);
      case 'add':
        if (resData.data.data.classes) {
          dispatch({
            type: 'ADD_MULTIPLE',
            classes: resData.data.data.classes,
          });
          return setAlert('Success', 'Created new Classes!');
        }
        dispatch({ type: 'ADD', class: resData.data.data.class });
        return setAlert('Success', 'Created a new Class!');
      case 'update':
        dispatch({ type: 'UPDATE', updatedClass: resData.data.data.data });
        return setAlert('Success', `Updated Class!`);
      default:
        break;
    }
  }, [resData, reqExtra, isLoading, error, reqId, setAlert]);

  const changeMonthHandler = event => {
    if (event.target.dataset.arrow === 'right')
      setDate(new Date(date.getFullYear(), date.getMonth() + 1));

    if (event.target.dataset.arrow === 'left')
      setDate(new Date(date.getFullYear(), date.getMonth() - 1));
  };

  const addClassHandler = classObj =>
    sendRequest('class', 'POST', classObj, classObj, 'add');

  const updateClassHandler = (formData, classObj) =>
    sendRequest(`class/${classObj._id}`, 'PATCH', formData, null, 'update');

  const deleteClassHandler = id => {
    if (!window.confirm('Delete this Class?')) return;
    sendRequest(`class/${id}`, 'DELETE', null, id, 'delete');
  };

  const selectDayHandler = day => {
    setSelectedDay(day);
    setShow(true);
  };

  const toggleModal = () => {
    setShow(!show);
    showCreateForm(false);
    setIsEdit(null);
    setIsAttendance(false);
  };

  const showClassForm = (event, id) => {
    if (id) {
      setIsEdit(id._id);
      setSelectedClass(id);
    }
    showCreateForm(!showForm);
  };

  const toggleCalendarDisplay = () => {
    setIsByWeekly(!isBiWeekly);
  };

  const attendanceClickHandler = classObj => {
    setIsAttendance(true);
    setSelectedClass(classObj);
  };

  const updateAttendance = useCallback(
    async classId => {
      sendRequest(`class/${classId}`, 'GET', null, null, 'update');

      await wait(0.5);
      setShow(false);
      setIsAttendance(false);
    },
    [sendRequest]
  );

  const setError = useCallback(
    message => {
      setAlert('Error', message);
    },
    [setAlert]
  );

  let modalContent = (
    <ExpandedDate
      day={selectedDay}
      click={showClassForm}
      close={toggleModal}
      deleteClass={deleteClassHandler}
      attendanceClick={attendanceClickHandler}
      user={user}
    />
  );

  if (showForm) {
    modalContent = (
      <ClassForm
        day={selectedDay}
        closed={toggleModal}
        date={date}
        created={addClassHandler}
      />
    );
    if (isEdit) {
      modalContent = (
        <ClassForm
          day={selectedDay}
          closed={toggleModal}
          date={date}
          updated={updateClassHandler}
          curClass={selectedClass}
          id={isEdit}
        />
      );
    }
  }

  if (isAttendance) {
    modalContent = (
      <Attendance
        currentClass={selectedClass}
        updateClass={updateAttendance}
        setError={setError}
        setSuccess={() => setAlert('Success', 'successfully logged attendance')}
        user={user}
      />
    );
  }

  let calendar = <Spinner />;

  if (!isLoading && daysArray.length > 1) {
    calendar = (
      <MonthlyCalendar
        dayClick={selectDayHandler}
        days={daysArray}
        changeMonth={event => changeMonthHandler(event)}
        user={user}
      />
    );
  }

  return (
    <div className={styles.Calendar}>
      {isAlert && <Alert msg={message} alertType={type} />}
      <Modal show={show} modalClosed={toggleModal}>
        {modalContent}
      </Modal>
      <CalendarHeader
        date={date}
        iconClicked={event => changeMonthHandler(event)}
        clicked={toggleCalendarDisplay}
        isWeekly={isBiWeekly}
      />
      {calendar}
    </div>
  );
};

export default Calendar;
