import React, { useState } from 'react';
import { objFormToArr, updateInputAndForm } from '../../../utils/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

const AlertForm = props => {
  const [alertForm, setAlertForm] = useState({
    message: {
      elementType: 'textarea',
      elementConfig: {
        type: 'text',
        placeholder: 'Enter Alert...',
      },
      label: 'Alert',
      value: '',
      validation: {
        required: true,
        minLength: 5,
        maxLength: 256,
      },
      valid: false,
      touched: false,
    },
    membershipType: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: '', displayValue: 'Please Choose' },
          { value: 'youth', displayValue: 'Youths' },
          { value: 'adult', displayValue: 'Adults' },
          { value: 'all', displayValue: 'All' },
        ],
      },
      label: 'Membership Type',
      value: '',
      validation: {},
      valid: true,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);
  // const { alert } = props;

  const sendAlert = e => {
    e.preventDefault();
    const formData = {};
    for (let formElementIdentifier in alertForm) {
      formData[formElementIdentifier] = alertForm[formElementIdentifier].value;
    }

    props.created(formData);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      alertForm,
      inputIdentifier
    );

    setAlertForm(updatedForm);
    setFormIsValid(valid);
  };

  let form = (
    <form onSubmit={sendAlert} aria-label="alertForm">
      {objFormToArr(alertForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button btnType="Success" disabled={!formIsValid}>
          Send Alert
        </Button>
        <Button btnType="Danger" clicked={props.closed}>
          Cancel
        </Button>
      </div>
    </form>
  );

  return <div>{form}</div>;
};
export default AlertForm;
