import React from 'react';

import styles from './UserSearchModal.module.scss';
import AttendanceSearch from '../Attendance/AttendanceSearch/AttendanceSearch';
import { AccountHolder } from '../../containers/MembershipDashboard/MembershipDashboard';

type Member = {
  id: string;
  name: string;
};

type Props = {
  members: Member[];
  selectUser: (id: string) => void;
  selectedUser: AccountHolder | null | undefined;
  queryName: string;
  setQueryName: (queryName: string) => void;
  inputRef: HTMLInputElement | undefined | any;
};

const UserSearchModal = ({
  members,
  selectUser,
  selectedUser,
  queryName,
  setQueryName,
  inputRef,
}: Props) => {
  return (
    <div className={styles.userSearch}>
      <div
        style={{
          position: 'relative',
        }}
      >
        <AttendanceSearch
          users={members}
          change={setQueryName}
          name={queryName}
          inputRef={inputRef}
          // focus={removeFocus}
          noList
          selectedUser={selectedUser}
        />
      </div>
      {members.map(el => {
        return (
          <p key={el.id} onClick={() => selectUser(el.id)}>
            {el.name}
          </p>
        );
      })}
    </div>
  );
};

export default UserSearchModal;
