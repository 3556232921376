import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from './context/authContext';

let stripeKey =
  'pk_test_51IEHgIIYLlrMHhAPNem2EcZd5hKqipGIRB8VNAxdrcnYKRRbWweWWe25vMKnIhawrlh0y0LW7bykUsSFnf2u3XtF00oQ6E5b6a';

if (process.env.NODE_ENV === 'production') {
  stripeKey =
    'pk_live_51IEHgIIYLlrMHhAPDsdzr7LQkPgwFFvPIryueXNV185rRxE87rUnmY7QzYtc5Qf6mDUiJzSbk6MLvlAngntHoc7S00YxxWzg3m';
}

const stripePromise = loadStripe(stripeKey);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </AuthContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
