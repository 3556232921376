import React from 'react';
import classes from './UserProfileNav.module.scss';

type Props = {
  navClick: (identifier: string) => string;
};

const UserProfileNav = ({ navClick }: Props) => {
  return (
    <div className={classes.nav}>
      <p className={classes.item} onClick={() => navClick('profile')}>
        Profile
      </p>
      <p className={classes.item} onClick={() => navClick('students')}>
        Students
      </p>
      <p className={classes.item}>Documents</p>
    </div>
  );
};

export default UserProfileNav;
