import React from 'react';
import styles from './Checkout.module.scss';
import CartForm from '../../../components/Forms/CartForm';
import { formatPrice } from '../../../utils/utils';
import { DetailedProduct } from '../Shop';

type Props = {
  discount: any;
  toggleDiscounts: () => void;
  discounts: any;
  setDiscount: any;
  stripe: boolean;
  cart: DetailedProduct[];
  order: (formData: any) => void;
  toggleModal: () => void;
  user: any;
};

const Checkout = (props: Props) => {
  let discountH: React.JSX.Element | null = (
    <div>
      <p onClick={props.toggleDiscounts}>Discount: {props.discount.name}</p>
      {props.discounts
        ? props.discounts.map((el: any) => (
            <p onClick={event => props.setDiscount(el)} key={el.id}>
              {el.name}
            </p>
          ))
        : null}
    </div>
  );

  if (!props.stripe) discountH = null;

  return (
    <div className={styles.checkout}>
      {props.cart.map((prod, i) => (
        <div key={prod.id + i} className={styles.cartItem}>
          <p>
            {i + 1}. {prod.name}
          </p>
          <p>{formatPrice(prod.price)}</p>
          <p>{prod.color}</p>
          <p>{prod.size}</p>
        </div>
      ))}
      <p>
        Total:{' '}
        {formatPrice(
          props.cart.reduce((acc, el) => {
            return acc + el.price;
          }, 0)
        )}
      </p>
      {discountH}
      <div>
        <CartForm
          created={props.order}
          closed={props.toggleModal}
          user={props.user}
          stripe={props.stripe}
        />
      </div>
    </div>
  );
};

export default Checkout;
