import React from 'react';

import classes from './UserInfo.module.scss';
import Button from '../../../UI/Button/Button';
import Spinner from '../../../UI/Spinner/Spinner';
import MemberForm from '../../../Forms/MemberForm/MemberForm';
import ChangePasswordForm from '../../../Forms/ChangePasswordForm/ChangePasswordForm';
import UserProfileNav from '../UserProfileNav/UserProfileNav';

const UserInfo = props => {
  let notes;

  if (props.account === '/account')
    return (
      <div className={classes.formContainer}>
        <UserProfileNav tester={props.tester} />
        <MemberForm currentUser={props.curUser} update={props.updateMe} />
        <ChangePasswordForm alert={props.alert} />
      </div>
    );

  if (props.curUser.role !== 'user' && props.member.notes) {
    notes = (
      <>
        <div className={classes.infoBlock}>
          <div className={classes.infoBlockHeader}>
            <h3 className={classes.header}>Notes</h3>
            <Button btnType={'Success'} clicked={props.noteClick}>
              Add Note
            </Button>
          </div>
          <div className={classes.infoBlockContent}>
            {props.member.notes.map(note => (
              <div className={classes.note} key={note._id}>
                <p className={classes.noteContent}>{note.note}</p>
                <p className={classes.noteDate}>
                  {new Date(note.createdAt).toLocaleString()}
                </p>
                <p className={classes.noteCreator}>
                  <span className={classes.by}>By:</span> {note.createdBy.name}
                </p>
                <div className={classes.iconBox}>
                  <svg
                    className={classes.icon}
                    onClick={event => props.noteClick(event, note)}
                  >
                    <use xlinkHref={`/img/icons.svg#icon-edit`}></use>
                  </svg>
                  <svg
                    className={[classes.icon, classes.trash].join(' ')}
                    onClick={() => props.deleteNote(note._id)}
                  >
                    <use xlinkHref={`/img/icons.svg#icon-trash`}></use>
                  </svg>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.btnBlock}>
          <Button btnType={'Success'} clicked={props.subClick}>
            Add Subscription
          </Button>
          <Button btnType={'Success'} clicked={props.subClick}>
            Manage Subscription
          </Button>
          <Button btnType={'Success'} clicked={props.studentClick}>
            Add Student
          </Button>
          <Button btnType={'Success'} clicked={props.paid}>
            Mark Paid
          </Button>
        </div>
      </>
    );
  }

  if (props.loading && !props.reqExtra) return <Spinner />;
  return (
    <div className={classes.userInfo}>
      <UserProfileNav navClick={props.navClick} />
      {notes}
    </div>
  );
};

export default UserInfo;
