import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import axios from '../../../axios-lab';
import { useParams, useNavigate } from 'react-router-dom';
import useHttp from '../../../hooks/http';
import { objFormToArr, updateInputAndForm } from '../../../utils/utils';
import classes from './ChangePassword.module.scss';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

const ChangePassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [resetPassForm, setResetPassForm] = useState({
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter Password...',
      },
      label: 'New Password',
      value: '',
      validation: {
        required: true,
        minLength: 6,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
    passwordConfirm: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter Password...',
      },
      label: 'Confirm New Password',
      value: '',
      validation: {
        required: true,
        minLength: 6,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);
  // const { resData, isLoading } = useHttp();
  // const { alert } = props;
  // useEffect(() => {
  //   // if (error) return alert('Error', error);
  //   if (isLoading) return;
  //   if (resData) {
  //     // return alert('Success', 'Successfully changed your password!');
  //   }
  // }, [resData]);

  const sendChangePasswordRequest = useMutation({
    mutationFn: formData => {
      return axios.patch(`/users/resetPassword/${params.token}`, formData);
    },
    onError: error => {
      // setAlert('Error', error.response.data.message);
    },
    onSuccess: res => {
      // setAlert('Success', `${res.data.count} alerts sent`);
      console.log('Success');
      navigate('/auth');
    },
  });

  const changePassword = e => {
    e.preventDefault();
    const formData = {};
    for (let formElementIdentifier in resetPassForm) {
      formData[formElementIdentifier] =
        resetPassForm[formElementIdentifier].value;
    }

    sendChangePasswordRequest.mutate(formData);

    // sendRequest('users/updateMyPassword', 'PATCH', formData);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      resetPassForm,
      inputIdentifier
    );

    setResetPassForm(updatedForm);
    setFormIsValid(valid);
  };

  let form = (
    <form
      onSubmit={changePassword}
      aria-label="passwordForm"
      className={classes.form}
    >
      {objFormToArr(resetPassForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button btnType="Success" disabled={!formIsValid}>
          Change Password
        </Button>
      </div>
    </form>
  );

  return (
    <div className={classes.formContainer}>
      <h1>Finish Resetting Password</h1>
      {form}
    </div>
  );
};

export default ChangePassword;
