import React, { useEffect, useState } from 'react';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { updateInputAndForm, objFormToArr } from '../../../utils/utils';

const NoteForm = props => {
  const [noteForm, setNoteForm] = useState({
    note: {
      elementType: 'textarea',
      elementConfig: {
        type: 'text',
        placeholder: 'Enter Note...',
      },
      label: 'Note',
      value: '',
      validation: {
        required: true,
        minLength: 5,
        maxLength: 500,
      },
      valid: false,
      touched: false,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const { note } = props;

  useEffect(() => {
    if (note) {
      setNoteForm(curNoteForm => {
        return { note: { ...curNoteForm.note, value: note.note } };
      });
    }
  }, [note]);

  const createOrEditNote = e => {
    e.preventDefault();
    const formData = {};
    for (let formElementIdentifier in noteForm) {
      formData[formElementIdentifier] = noteForm[formElementIdentifier].value;
    }

    if (note) return props.update(formData);

    props.created(formData);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      noteForm,
      inputIdentifier
    );

    setNoteForm(updatedForm);
    setFormIsValid(valid);
  };

  let form = (
    <form onSubmit={createOrEditNote} aria-label="noteForm">
      {objFormToArr(noteForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button btnType="Success" disabled={!formIsValid}>
          {note ? 'Update Note' : 'Create Note'}
        </Button>
        <Button btnType="Danger" clicked={props.closed}>
          Cancel
        </Button>
      </div>
    </form>
  );

  return <div>{form}</div>;
};

export default NoteForm;
