import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import axios from '../../axios-lab';
import classes from './Attendance.module.css';
import Button from '../../components/UI/Button/Button';
import AttendanceHeader from '../../components/Attendance/AttendanceHeader/AttendanceHeader';
import AttendanceSearch from '../../components/Attendance/AttendanceSearch/AttendanceSearch';
import AttendanceList from '../../components/Attendance/AttendanceList/AttendanceList';
import { wait } from '../../utils/utils';
import useHttp from '../../hooks/http';

const Attendance = React.memo(props => {
  const [changed, setChanged] = useState(false);
  const [touched, setTouched] = useState(false);
  const [queriedUsers, setQueriedUsers] = useState([]);
  const [attendedUsers, setAttendedUsers] = useState(
    props.currentClass.attendance.map(el => el.user)
  );
  const [attendedUserIds, setAttendedUserIds] = useState(
    props.currentClass.attendance.map(el => el.user._id)
  );
  const [queryName, setQueryName] = useState('');
  const inputRef = useRef();
  const { setError, updateClass, user, currentClass } = props;
  const { sendRequest, resData, isLoading, reqId, error } = useHttp();
  const navigate = useNavigate();

  const attendClass = useMutation({
    mutationFn: formData => {
      const postObj = {};

      return axios.post(`/attendance/log/${props.currentClass._id}`, postObj);
    },
    onError: error => {
      // setAlert('Error', error.response.data.message);
      props.setError(error.response.data.message);
    },
    onSuccess: res => {
      // setAlert('Success', `${res.data.count} alerts sent`);
      props.setSuccess();
      setAttendedUsers(prevState => {
        return [...prevState, res.data.data.user];
      });
      // (async () => {
      //   await wait(1);
      //   window.location.reload();
      // })();
    },
  });

  useEffect(() => {
    if (user.role === 'user') return;

    if (inputRef.current.value !== '') {
      setTouched(false);
    }

    if (touched) {
      return setQueriedUsers(prevState =>
        prevState.filter(el => !attendedUserIds.includes(el._id))
      );
    }

    // closure happens here due to setTimeout, queryName will be was what typed in 500ms ago
    // while inputRef.current.value will be the current input
    (async () => {
      await wait(0.5);
      if (queryName === inputRef.current.value) {
        await sendRequest(
          `students?like=${inputRef.current.value}`,
          'GET',
          null,
          null,
          'SET'
        );
      }
    })();
  }, [
    sendRequest,
    attendedUserIds,
    setError,
    inputRef,
    queryName,
    touched,
    user,
  ]);

  useEffect(() => {
    if (resData && !isLoading && reqId === 'SET') {
      setQueriedUsers(
        resData.data.data.users.filter(el => !attendedUserIds.includes(el._id))
      );
    }

    if (resData && !isLoading && !error && reqId === 'UPDATE') {
      updateClass(currentClass._id);
    }
  }, [
    error,
    isLoading,
    reqId,
    resData,
    attendedUserIds,
    updateClass,
    currentClass,
  ]);

  const addUserToAttendanceArray = user => {
    setQueriedUsers(queriedUsers.filter(el => el._id !== user._id));
    setAttendedUsers(prevState => [
      ...prevState,
      { _id: user._id, name: user.name },
    ]);
    setAttendedUserIds(prevState => [...prevState, user._id]);
    setTouched(true);
    setChanged(true);

    if (queryName) {
      setQueryName('');
      setTouched(false);
    }
  };

  const removeUserFromAttendance = (event, user) => {
    event.stopPropagation();
    setAttendedUsers(attendedUsers.filter(el => el._id !== user._id));
    setQueriedUsers(prevState => [
      ...prevState,
      { _id: user._id, name: user.name },
    ]);
    setAttendedUserIds(attendedUserIds.filter(el => el !== user._id));
    setTouched(true);
    setChanged(true);

    if (queryName) {
      setQueryName('');
      setTouched(false);
    }
  };

  const postAttendanceArray = () => {
    const formattedAttendance = attendedUserIds.map(userId => {
      return { user: userId, class: currentClass._id };
    });

    sendRequest(
      `class/${props.currentClass._id}/attendance`,
      'POST',
      {
        attendances: formattedAttendance,
      },
      null,
      'UPDATE'
    );
  };

  const toUserProfileHandler = userId => {
    navigate(`/member/${userId}`);
  };

  if (props.user.role === 'user') {
    return (
      <div className={classes.Attendance}>
        <AttendanceHeader class={props.currentClass} />
        <AttendanceList
          users={attendedUsers}
          removeUser={removeUserFromAttendance}
          clicked={toUserProfileHandler}
          user={user}
        />
        <Button btnType={'Success'} clicked={attendClass.mutate}>
          Attend
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.Attendance}>
      <AttendanceHeader class={props.currentClass} />
      <AttendanceSearch
        users={queriedUsers}
        change={setQueryName}
        addUser={addUserToAttendanceArray}
        name={queryName}
        inputRef={inputRef}
      />
      <AttendanceList
        users={attendedUsers}
        removeUser={removeUserFromAttendance}
        clicked={toUserProfileHandler}
        user={user}
      />
      <Button
        clicked={postAttendanceArray}
        btnType={'Success'}
        disabled={!changed}
      >
        Save
      </Button>
    </div>
  );
});

export default Attendance;
