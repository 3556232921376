import { useReducer, useEffect, useCallback } from 'react';

const initialState: MainAlert = {
  isAlert: false,
  alert: {
    message: null,
    alertType: null,
  },
};

type Alert = {
  message: string | null;
  alertType: string | null;
};

type MainAlert = {
  isAlert: boolean;
  alert: Alert;
};

type Action =
  | { type: 'SET'; message: string; alertType: string }
  | { type: 'CLEAR' };

const alertReducer = (currentAlert: MainAlert, action: Action) => {
  switch (action.type) {
    case 'SET':
      return {
        isAlert: true,
        alert: { alertType: action.alertType, message: action.message },
      };
    case 'CLEAR':
      return initialState;
    default:
      return initialState;
  }
};
// test commit

const useAlert = () => {
  const [alert, dispatchAlert] = useReducer(alertReducer, initialState);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (alert.isAlert) {
        dispatchAlert({ type: 'CLEAR' });
      }
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [alert]);

  const setAlert = useCallback((type: string, message: string) => {
    dispatchAlert({ type: 'CLEAR' });
    dispatchAlert({ type: 'SET', alertType: type, message: message });
  }, []);

  // const clearAlert = useCallback(() => {
  //   dispatchAlert({type: 'CLEAR'})
  // }, []);

  return {
    isAlert: alert.isAlert,
    message: alert.alert.message,
    type: alert.alert.alertType,
    setAlert: setAlert,
  };
};

export default useAlert;
