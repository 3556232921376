import React, { useContext } from 'react';

import Navigation from '../Navigation/Navigation';

import classes from './Layout.module.scss';
import TopBar from '../TopBar/TopBar';
import { AuthContext } from '../../context/authContext';

const Layout = props => {
  const { isAuth, user, logout } = useContext(AuthContext);

  return (
    <>
      <Navigation user={user} auth={isAuth} logout={logout} />
      <TopBar />
      <main
        className={classes.main}
        style={!isAuth ? { gridColumn: 'full-start / full-end' } : null}
      >
        {props.children}
      </main>
    </>
  );
};

export default Layout;
