import React from 'react';

import classes from './AttendanceList.module.scss';

const AttendanceList = props => {
  if (props.user.role === 'user') {
    return (
      <ul className={classes.attended}>
        {props.users.map(user => (
          <div className={classes.user} key={user._id}>
            <p className={classes.name}>{user.name}</p>
          </div>
        ))}
      </ul>
    );
  }

  return (
    <ul className={classes.attended}>
      {props.users.map(user => (
        <div className={classes.user} key={user._id}>
          <p className={classes.name} onClick={() => props.clicked(user._id)}>
            {user.name}
          </p>
          <svg
            className={classes.icon}
            onClick={event => props.removeUser(event, user)}
          >
            <use xlinkHref={`/img/icons.svg#icon-trash`} />
          </svg>
        </div>
      ))}
    </ul>
  );
};

export default AttendanceList;
