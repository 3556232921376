import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from '../../../axios-lab';
import { objFormToArr, updateInputAndForm, wait } from '../../../utils/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import classes from '../AuthForm/AuthForm.module.scss';
import Alert from '../../UI/Alert/Alert';
import useAlert from '../../../hooks/alert';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../UI/Spinner/Spinner';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { setAlert, isAlert, message, type } = useAlert();
  const [resetForm, setResetForm] = useState({
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'renato@gmail.com',
      },
      label: 'Email',
      value: '',
      validation: {
        required: true,
        minLength: 6,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);

  const sendResetPasswordEmail = useMutation({
    mutationFn: email => {
      const postObj = {
        email,
      };

      return axios.post(`/users/forgotPassword`, postObj);
    },
    onError: error => {
      console.label('inside error');
      setAlert('Error', error.response.data.message);
    },
    onSuccess: res => {
      setAlert('Success', `Successfully sent email`);
      (async () => {
        await wait(2);
        navigate('/auth');
      })();
    },
  });

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      resetForm,
      inputIdentifier
    );
    setFormIsValid(valid);
    setResetForm(updatedForm);
  };
  const submitHandler = e => {
    e.preventDefault();

    sendResetPasswordEmail.mutate(resetForm.email.value);

    return setResetForm({
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'renato@gmail.com',
        },
        label: 'Email',
        value: '',
        validation: {
          required: true,
          minLength: 6,
          maxLength: 100,
        },
        valid: false,
        touched: false,
      },
    });
  };

  let form = (
    <form onSubmit={submitHandler} aria-label="noteForm">
      {objFormToArr(resetForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button btnType="Success" disabled={!formIsValid}>
          Reset Password
        </Button>
      </div>
    </form>
  );

  if (sendResetPasswordEmail.isLoading) return <Spinner />;

  return (
    <>
      {isAlert && <Alert msg={message} alertType={type} />}
      <div className={classes.formContainer}>
        <h1 className={classes.title}>The Fight Lab</h1>
        {form}
      </div>
    </>
  );
};

export default ResetPassword;
