import React from 'react';

import styles from './OrderList.module.scss';
import { formatPrice } from '../../../utils/utils';

const OrderList = props => {
  return (
    <div className={styles.orderList}>
      {props.orders.map(order => {
        if (order.memberships.length > 0) {
          return (
            <div
              className={styles.order}
              key={order.id}
              onClick={() => props.clicked(order.id)}
            >
              <div className={styles.leftArea}>
                <p className={styles.header}>Order #</p>
                <p className={styles.underHeader}>{order.orderNo}</p>
                <p className={styles.header}>Total</p>
                <p className={styles.underHeader}>
                  {formatPrice(order.total, true)}
                </p>
              </div>
              <div className={styles.productBox}>
                <p className={styles.name}> Subscription Update </p>
              </div>
              <div className={styles.rightArea}>
                <p className={styles.header}>Order Placed</p>
                <p className={styles.underHeader}>
                  {new Date(order.datePurchased).toLocaleDateString()}
                </p>
                <p className={styles.header}>Purchaser</p>
                <p className={styles.underHeader}>{order?.purchaser?.name}</p>
              </div>
            </div>
          );
        }
        return (
          <div
            className={styles.order}
            key={order.id}
            onClick={() => props.clicked(order.id)}
          >
            <div className={styles.leftArea}>
              <p className={styles.header}>Order #</p>
              <p className={styles.underHeader}>{order.orderNo}</p>
              <p className={styles.header}>Total</p>
              <p className={styles.underHeader}>
                {formatPrice(order.total, true)}
              </p>
            </div>
            <div className={styles.productBox}>
              {order.products.map((prod, i) => (
                <div className={styles.product} key={prod.id + i}>
                  <p className={styles.name}>
                    <span>{i + 1}. </span>
                    {prod.name}
                  </p>
                  {window.innerWidth <= 500 ? null : (
                    <>
                      <p className={styles.size}>{prod.size}</p>
                      <p className={styles.color}>{prod.color}</p>
                    </>
                  )}

                  <p className={styles.price}>{formatPrice(prod.price)}</p>
                </div>
              ))}
            </div>
            <div className={styles.rightArea}>
              <p className={styles.header}>Order Placed</p>
              <p className={styles.underHeader}>
                {new Date(order.datePurchased).toLocaleDateString()}
              </p>
              <p className={styles.header}>Purchaser</p>
              <p className={styles.underHeader}>{order?.purchaser?.name}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderList;
