import React from 'react';
import classes from './ProfitLossBox.module.scss';

type Profit = {
  _id: string;
  costOfGoods: number;
  count: number;
  membershipSales: number;
  productSales: number;
  profit: number;
};

type Props = {
  profits: Profit[] | Error | React.JSX.Element;
};

const ProfitLossBox = (props: Props) => {
  if (!Array.isArray(props.profits)) return null;
  console.log(props.profits);
  return (
    <div className={classes.plContainer}>
      <div className={classes.infoBox}>
        <p className={classes.label}>COGS</p>
        <p className={classes.value}>
          $
          {props.profits.reduce((acc, item) => {
            return item.costOfGoods + acc;
          }, 0) / 100}
        </p>
      </div>
      <div className={classes.infoBox}>
        <p className={classes.label}>Inventory Revenue</p>
        <p className={classes.value}>
          $
          {props.profits.reduce((acc, item) => {
            if (item._id === 'Private Lessons') {
              return acc;
            }
            return item.productSales + acc;
          }, 0) / 100}
        </p>
      </div>
      <div className={classes.infoBox}>
        <p className={classes.label}>Inventory Profits</p>
        <p className={classes.value}>
          $
          {props.profits.reduce((acc, item) => {
            if (item._id === 'Private Lessons') {
              return acc;
            }
            return item.profit + acc;
          }, 0) / 100}
        </p>
      </div>
      <div className={classes.infoBox}>
        <p className={classes.label}>Memberships</p>
        <p className={classes.value}>
          $
          {props.profits.reduce((acc, item) => {
            return item.membershipSales + acc;
          }, 0) / 100}
        </p>
      </div>
    </div>
  );
};

export default ProfitLossBox;
