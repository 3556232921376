import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import classes from './Navigation.module.scss';
import NavigationLink from './NavigationLink/NavigationLink';
import Button from '../UI/Button/Button';
import Backdrop from '../UI/Backdrop/Backdrop';
import MobileNavigation from '../UI/MobileNavigation/MobileNavigation';

type User = {
  name: string;
  role: string;
};

type Props = {
  user: User;
  logout: () => void;
};

const Navigation = (props: Props) => {
  const [navToggle, setNavToggle] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setNavToggle(false);
  }, [location.pathname]);

  const toggleMobileNav = () => {
    setNavToggle(!navToggle);
  };

  let navigation = null;
  const navClasses = [classes.Navigation];

  if (navToggle) navClasses.push(classes.open);

  // Hack to prevent user from being null in if check
  // should be a better way to prevent this
  if (!props.user) return null;

  switch (props.user.role) {
    case 'user':
      navigation = (
        <>
          <Backdrop show={navToggle} clicked={toggleMobileNav} />
          <nav className={navClasses.join(' ')}>
            <NavigationLink navLink={'/calendar'} name={'Calendar'} />
            <NavigationLink navLink={'/account'} name={'Account'} />
            <NavigationLink navLink={'/attendance'} name={'Attendance'} />
            <Button
              btnType={'Danger'}
              where={'Nav'}
              clicked={props.logout}
              disabled={false}
            >
              Logout
            </Button>
          </nav>
          <MobileNavigation clicked={toggleMobileNav} open={navToggle} />
        </>
      );
      break;
    case 'instructor':
      navigation = (
        <>
          <Backdrop show={navToggle} clicked={toggleMobileNav} />
          <nav className={navClasses.join(' ')}>
            {/*<NavigationLink navLink={'/'} name={'Overview'} />*/}
            <NavigationLink navLink={'/account'} name={'Account'} />
            <NavigationLink navLink={'/members'} name={'Members'} />
            <NavigationLink navLink={'/calendar'} name={'Calendar'} />
            <Button
              btnType={'Danger'}
              where={'Nav'}
              clicked={props.logout}
              disabled={false}
            >
              Logout
            </Button>
          </nav>
          <MobileNavigation clicked={toggleMobileNav} open={navToggle} />
        </>
      );
      break;
    case 'admin':
      navigation = (
        <>
          <Backdrop show={navToggle} clicked={toggleMobileNav} />
          <nav className={navClasses.join(' ')}>
            {/*<NavigationLink navLink={'/'} name={'Overview'} />*/}
            <NavigationLink navLink={'/account'} name={'Account'} />
            <NavigationLink navLink={'/members'} name={'Accounts'} />
            <NavigationLink navLink={'/crm'} name={'CRM'} />
            <NavigationLink
              navLink={'/businessIntelligence'}
              name={'Business Intelligence'}
            />
            <NavigationLink navLink={'/billing'} name={'Billing'} />
            <NavigationLink navLink={'/memberships'} name={'Memberships'} />
            <NavigationLink navLink={'/alerts'} name={'Alerts'} />
            <NavigationLink navLink={'/orders'} name={'Orders'} />
            <NavigationLink navLink={'/shop'} name={'Shop'} />
            <NavigationLink navLink={'/inventory'} name={'Inventory'} />
            <NavigationLink navLink={'/calendar'} name={'Calendar'} />
            <Button
              btnType={'Danger'}
              where={'Nav'}
              clicked={props.logout}
              disabled={false}
            >
              Logout
            </Button>
          </nav>
          <MobileNavigation clicked={toggleMobileNav} open={navToggle} />
        </>
      );
      break;
  }

  // if (props.user.role === 'user')
  //   navigation = (
  //     <>
  //       <Backdrop show={navToggle} clicked={toggleMobileNav} />
  //       <nav className={navClasses.join(' ')}>
  //         <NavigationLink navLink={'/calendar'} name={'Calendar'} />
  //         <NavigationLink navLink={'/account'} name={'Account'} />
  //         <NavigationLink navLink={'/attendance'} name={'Attendance'} />
  //
  //         <Button2 btnType={'Danger'} where={'Nav'} clicked={props.logout}>
  //           Logout
  //         </Button2>
  //       </nav>
  //       <MobileNavigation clicked={toggleMobileNav} open={navToggle} />
  //     </>
  //   );

  // if (props.user.role === 'admin') {
  //   navigation = (
  //     <>
  //       <Backdrop show={navToggle} clicked={toggleMobileNav} />
  //       <nav className={navClasses.join(' ')}>
  //         {/*<NavigationLink navLink={'/'} name={'Overview'} />*/}
  //         <NavigationLink navLink={'/account'} name={'Account'} />
  //         <NavigationLink navLink={'/members'} name={'Members'} />
  //         <NavigationLink navLink={'/billing'} name={'Billing'} />
  //         <NavigationLink navLink={'/orders'} name={'Orders'} />
  //         <NavigationLink navLink={'/shop'} name={'Shop'} />
  //         <NavigationLink navLink={'/inventory'} name={'Inventory'} />
  //         <NavigationLink navLink={'/calendar'} name={'Calendar'} />
  //         <Button2 btnType={'Danger'} where={'Nav'} clicked={props.logout}>
  //           Logout
  //         </Button2>
  //       </nav>
  //       <MobileNavigation clicked={toggleMobileNav} open={navToggle} />
  //     </>
  //   );
  // }

  return navigation;
};

export default Navigation;
