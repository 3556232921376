import React, { useState, useEffect } from 'react';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import { updateInputAndForm, objFormToArr } from '../../../utils/utils';

let options = [
  { value: '', displayValue: 'Please Choose' },
  { value: '602746815518a63775064e2a', displayValue: 'Jeremy Loflin' },
  { value: '6020bfae4d93cf33fc604a5c', displayValue: 'David Kelly' },
  { value: '602747375518a63775064e2b', displayValue: 'Lance Farrar' },
  { value: '647516e6e5cb500e00250dba', displayValue: 'Ryan Briggs' },
  { value: '6033c80a5518a63775064e65', displayValue: 'Allan Reyes' },
];

if (process.env.NODE_ENV === 'development') {
  options = [
    { value: '', displayValue: 'Please Choose' },
    { value: '5e691bac12ba6520e8b5fa0c', displayValue: 'David Kelly' },
  ];
}

const ClassForm = props => {
  const [classForm, setClassForm] = useState({
    instructor: {
      elementType: 'select',
      elementConfig: {
        options: options,
      },
      label: 'Instructor',
      value: '',
      validation: {},
      valid: true,
    },
    name: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: '', displayValue: 'Please Choose' },
          { value: 'Gi BJJ', displayValue: 'Gi BJJ' },
          { value: 'No-Gi BJJ', displayValue: 'No-Gi BJJ' },
          {
            value: 'Drills BJJ',
            displayValue: 'Drills BJJ',
          },
          // { value: 'Competition BJJ', displayValue: 'Competition BJJ' },
          { value: 'Kickboxing', displayValue: 'Kickboxing' },
          { value: 'MMA', displayValue: 'MMA' },
          { value: 'Wrestling', displayValue: 'Wrestling' },
          { value: 'Kids Class', displayValue: 'Kids Class' },
          // { value: 'Little Ninjas', displayValue: 'Little Ninjas' },
        ],
      },
      label: 'Class Name',
      value: '',
      validation: {},
      valid: true,
    },
    time: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Please Enter Time',
      },
      label: 'Time',
      value: '',
      validation: {
        required: true,
        minLength: 5,
      },
      valid: false,
      touched: false,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [dayAy, setDayAy] = useState();
  const [repeat, setRepeat] = useState(false);
  const { curClass } = props;

  useEffect(() => {
    setDayAy(new Date(props.date.setUTCDate(parseInt(props.day.day))));
  }, [props.date, props.day.day]);

  useEffect(() => {
    if (curClass)
      setClassForm(form => {
        return {
          instructor: { ...form.instructor, value: curClass.instructor.id },
          name: { ...form.name, value: curClass.name },
          time: { ...form.time, value: curClass.time },
        };
      });
  }, [curClass]);

  const createOrEditClass = e => {
    e.preventDefault();

    const formData = {};
    for (let formElementIdentifier in classForm) {
      formData[formElementIdentifier] = classForm[formElementIdentifier].value;
    }

    formData.date = dayAy;
    formData.constant = repeat;

    if (curClass) return props.updated(formData, curClass);

    props.created(formData);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      classForm,
      inputIdentifier
    );

    setClassForm(updatedForm);
    setFormIsValid(valid);
  };

  const toggleRepeat = () => {
    setRepeat(!repeat);
  };

  const activeStyle = {
    padding: '1rem 1rem',
    color: 'green',
    marginBottom: '2rem',
  };

  const inactiveStyle = {
    color: 'grey',
    padding: '1rem 1rem',
    marginBottom: '2rem',
  };

  let form = (
    <form onSubmit={createOrEditClass}>
      {objFormToArr(classForm).map(formElement => {
        return (
          <Input
            key={formElement.id}
            label={formElement.config.label}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={event => inputChangedHandler(event, formElement.id)}
          />
        );
      })}
      <p style={repeat ? activeStyle : inactiveStyle} onClick={toggleRepeat}>
        Repeat
      </p>
      <Button btnType="Success" disabled={!formIsValid}>
        {curClass ? 'Update Class' : 'Create Class'}
      </Button>
      <Button btnType="Danger" clicked={props.closed}>
        Cancel
      </Button>
    </form>
  );

  return <div>{form}</div>;
};

export default ClassForm;
