import React, { useState } from 'react';

import styles from './InventoryList.module.scss';
import { formatPrice } from '../../../utils/utils';
import Modal from '../../../components/UI/Modal/Modal';
import ProductForm from '../../../components/Forms/ProductForm/ProductForm';

const InventoryList = props => {
  const [accordionShow, setAccordionShow] = useState(false);
  const [selectedProd, setSelectedProd] = useState({});
  const [variety, setVariety] = useState(false);

  const toggleAccordion = prod => {
    setSelectedProd(prod);
    setAccordionShow(!accordionShow);
  };

  const onVarietyClick = () => {
    setVariety(true);
  };

  const products = props.products.map(product => {
    const listStyles = [styles.listItemAccordion, styles.hidden];

    // early escape if there is only 1 product

    let varieties = product.varietys.map(prod => {
      return (
        <span
          className={listStyles.join(' ')}
          key={prod.id}
          onClick={() => props.toggleModal(prod.id)}
          // onClick={() => toggleAccordion(product)}
        >
          <p className={styles.name}>{prod.name}</p>
          <p className={styles.size}>{prod.size}</p>
          <p className={styles.price}>{prod.color}</p>
          {window.innerWidth >= 500 ? (
            <p className={styles.price}>{formatPrice(prod.cost)}</p>
          ) : null}
          <p className={styles.price}>{formatPrice(prod.price)}</p>
          {window.innerWidth >= 500 ? (
            <p className={styles.price}>{formatPrice(prod.potentialProfit)}</p>
          ) : null}
          <p className={styles.quantity}>{prod.quantity}</p>
        </span>
      );
    });

    if (product.varietys.length === 1) {
      return (
        <>
          <div
            className={styles.listItem}
            key={product.name}
            onClick={() => props.toggleModal(product.varietys[0].id)}
            // onClick={() => toggleAccordion(product)}
          >
            <p className={styles.name}>{product.name}</p>
            <p className={styles.size}>{product.varietys[0].size}</p>
            <p className={styles.price}>{product.varietys[0].color}</p>
            {window.innerWidth >= 500 ? (
              <p className={styles.price}>
                {formatPrice(product.varietys[0].cost)}
              </p>
            ) : null}
            <p className={styles.price}>
              {formatPrice(product.varietys[0].price)}
            </p>
            {window.innerWidth >= 500 ? (
              <p className={styles.price}>
                {formatPrice(product.varietys[0].potentialProfit)}
              </p>
            ) : null}

            <p className={styles.quantity}>{product.varietys[0].quantity}</p>
          </div>
          {varieties}
        </>
      );
    }

    if (accordionShow && selectedProd.name === product.name) {
      varieties = product.varietys.map(prod => {
        return (
          <span
            className={styles.listItemAccordion}
            key={prod.id}
            onClick={event => props.toggleModal(prod.id)}
            // onClick={() => toggleAccordion(product)}
          >
            <p className={styles.name}>{prod.name}</p>
            <p className={styles.size}>{prod.size}</p>
            <p className={styles.price}>{prod.color}</p>
            {window.innerWidth >= 500 ? (
              <p className={styles.price}>{formatPrice(prod.cost)}</p>
            ) : null}
            <p className={styles.price}>{formatPrice(prod.price)}</p>
            {window.innerWidth >= 500 ? (
              <p className={styles.price}>
                {formatPrice(prod.potentialProfit)}
              </p>
            ) : null}
            <p className={styles.quantity}>{prod.quantity}</p>
          </span>
        );
      });
      return (
        <>
          <div
            className={styles.listItem + ' ' + styles.accordionHeader}
            key={product.name}
            // onClick={event => props.toggleModal(product.id)}
            onClick={() => toggleAccordion(product)}
          >
            <p className={styles.name + ' ' + styles.bold}>{product.name}</p>
            {/*<p className={styles.size}></p>*/}
            {/*<p className={styles.price}>{product.color}</p>*/}
            {/*{window.innerWidth >= 500 ? (*/}
            {/*  <p className={styles.price}>{formatPrice(product.cost)}</p>*/}
            {/*) : null}*/}
            {/*<p className={styles.price}>{formatPrice(product.price)}</p>*/}
            {/*{window.innerWidth >= 500 ? (*/}
            {/*  <p className={styles.price}>*/}
            {/*    {formatPrice(product.potentialProfit)}*/}
            {/*  </p>*/}
            {/*) : null}*/}
            {/*<p className={styles.quantity}>{product.quantity}</p>*/}
          </div>
          {varieties}
        </>
      );
    }

    return (
      <>
        <div
          className={styles.listItem}
          key={product.name}
          // onClick={event => props.toggleModal(product.id)}
          onClick={() => toggleAccordion(product)}
        >
          <p className={styles.name}>{product.name}</p>
          {/*<p className={styles.size}>{product.size}</p>*/}
          {/*<p className={styles.price}>{product.color}</p>*/}
          {/*{window.innerWidth >= 500 ? (*/}
          {/*  <p className={styles.price}>{formatPrice(product.cost)}</p>*/}
          {/*) : null}*/}
          {/*<p className={styles.price}>{formatPrice(product.price)}</p>*/}
          {/*{window.innerWidth >= 500 ? (*/}
          {/*  <p className={styles.price}>*/}
          {/*    {formatPrice(product.potentialProfit)}*/}
          {/*  </p>*/}
          {/*) : null}*/}

          {/*<p className={styles.quantity}>{product.quantity}</p>*/}
        </div>
        {varieties}
      </>
    );
  });

  let modalContent = (
    <>
      <div className={styles.row}>
        <p className={styles.label}>Name:</p>
        <p className={styles.content}>{props.product?.name}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.label}>Size:</p>
        <p className={styles.content}>{props.product?.size}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.label}>Color:</p>
        <p className={styles.content}>{props.product?.color}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.label}>Description:</p>
        <p className={styles.content}>{props.product?.description}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.label}>Price:</p>
        <p className={styles.content}>{formatPrice(props.product?.price)}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.label}>Cost:</p>
        <p className={styles.content}>{formatPrice(props.product?.cost)}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.label}>Profit:</p>
        <p className={styles.content}>
          {formatPrice(props?.product?.potentialProfit)}
        </p>
      </div>
      <div className={styles.row}>
        <p className={styles.label}>SKU:</p>
        <p className={styles.content}>{props.product?.sku}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.label}>Quantity:</p>
        <p className={styles.content}>{props.product?.quantity}</p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <svg className={styles.icon} onClick={onVarietyClick}>
          <use xlinkHref={`/img/icons.svg#icon-play`}></use>
        </svg>
        <svg className={styles.icon} onClick={props.viewUpdate}>
          <use xlinkHref={`/img/icons.svg#icon-edit`}></use>
        </svg>
        <svg
          className={[styles.icon, styles.danger].join(' ')}
          onClick={props.delete}
        >
          <use xlinkHref={`/img/icons.svg#icon-trash`}></use>
        </svg>
      </div>
    </>
  );

  if (props.form) {
    modalContent = (
      <ProductForm created={props.createProduct} closed={props.toggleModal} />
    );
  }

  if (props.update) {
    modalContent = (
      <ProductForm
        created={props.createProduct}
        closed={props.toggleModal}
        update={props.updateProduct}
        product={props.product}
      />
    );
  }

  if (variety) {
    modalContent = (
      <ProductForm
        created={props.createProduct}
        closed={props.toggleModal}
        product={props.product}
        update={props.createProduct}
      />
    );
  }

  return (
    <div className={styles.list}>
      <Modal show={props.show} modalClosed={props.toggleModal}>
        <div
          style={{
            maxHeight: '50rem',
          }}
        >
          {modalContent}
        </div>
      </Modal>
      <div className={styles.listHeader}>
        <p className={styles.name}>Name</p>
        <p className={styles.size}>Size</p>
        <p className={styles.price}>Color</p>
        {window.innerWidth >= 500 ? <p className={styles.price}>Cost</p> : null}

        <p className={styles.price}>Price</p>
        {window.innerWidth >= 500 ? (
          <p className={styles.price}>Profit</p>
        ) : null}
        <p className={styles.quantity}>#</p>
      </div>
      {products}
    </div>
  );
};

export default InventoryList;
