import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useAlert from '../hooks/alert';
import useHttp from '../hooks/http';
import Alert from '../components/UI/Alert/Alert';

export const AuthContext = React.createContext({
  isAuth: false,
  user: null,
  login: (email: string, password: string) => {},
  logout: () => {},
  signup: (token: string, password: string, passwordConfirm: string) => {},
  me: () => {},
});

type Props = {
  children: React.ReactNode;
};

const AuthContextProvider = (props: Props) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authenticatedUser, setAuthUser] = useState(null);
  const { isAlert, type, message, setAlert } = useAlert();
  const { sendRequest, resData, error, reqId } = useHttp();

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      setIsAuthenticated(true);
      sendRequest('users/me', 'GET', null, null, 'me');
    }
  }, [sendRequest]);

  useEffect(() => {
    // weird hack thing to remove localStorage if we get stuck in a me request loop without
    if (!resData && reqId === 'me') {
      localStorage.removeItem('token');
    }
    if (error) {
      localStorage.removeItem('token');
      setIsAuthenticated(false);
      return setAlert('Error', error);
    }
    if (!resData) return;
    let user;

    switch (reqId) {
      case 'logout':
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setAuthUser(null);
        return setAlert('Success', 'Logged Out Successfully');
      case 'login':
        if (localStorage.getItem('token')) return;
        user = resData.data.data.user;
        localStorage.setItem('token', 'slimy');
        setAuthUser(user);
        setIsAuthenticated(true);
        if (user.role === 'user') {
          navigate('/account');
        } else {
          navigate('/account');
        }
        return setAlert('Success', 'Logged In Successfully');
      case 'signup':
        user = resData.data.data.user;
        localStorage.setItem('token', 'slimy');
        setAuthUser(user);
        setIsAuthenticated(true);
        if (user.role === 'user') {
          navigate('/account');
        } else {
          navigate('/');
        }
        return setAlert('Success', 'Signed Up Successfully!');
      case 'me':
        user = resData.data.data.data;
        localStorage.setItem('token', 'slimy');
        return setAuthUser(user);
      default:
    }
  }, [resData, reqId, setAlert, error, navigate]);

  const logoutHandler = useCallback(
    () => sendRequest('users/logout', 'GET', null, null, 'logout'),
    [sendRequest]
  );

  const loginHandler = useCallback(
    (email: string, password: string) => {
      sendRequest('users/login', 'POST', { email, password }, null, 'login');
    },
    [sendRequest]
  );

  const me = useCallback(() => {
    sendRequest('users/me', 'GET', null, null, 'me');
  }, [sendRequest]);

  const signupHandler = useCallback(
    (token: string, password: string, passwordConfirm: string) =>
      sendRequest(
        `users/signup/${token}`,
        'PATCH',
        {
          password,
          passwordConfirm,
        },
        null,
        'signup'
      ),
    [sendRequest]
  );

  return (
    <AuthContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        signup: signupHandler,
        me: me,
        isAuth: isAuthenticated,
        user: authenticatedUser,
      }}
    >
      {isAlert && <Alert alertType={type} msg={message} />}
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
