import React from 'react';
import classes from './Button.module.scss';

type Props = {
  disabled?: boolean;
  btnType: string;
  where: string;
  clicked: () => void;
  children: React.ReactNode;
};

const Button = (props: Props) => {
  return (
    <button
      disabled={props.disabled}
      className={[
        classes.Button,
        classes[props.btnType],
        classes[props.where],
      ].join(' ')}
      onClick={props.clicked}
      type={props.btnType === 'Danger' ? 'button' : undefined}
    >
      {props.children}
    </button>
  );
};

export default Button;
