import React from 'react';
import classes from './UserDetails.module.scss';

type Member = {
  phoneNumber: string;
  photo: string;
  name: string;
  email: string;
  createdAt: string;
};

type Props = {
  member: Member;
  back: () => void;
  isActive: boolean;
};

const UserDetails = ({ member, back, isActive }: Props) => {
  return (
    <div className={classes.userDetailBlock}>
      <p className={classes.backButton} onClick={back}>
        Go Back
      </p>
      <div className={classes.imageBlock}>
        <img
          className={classes.image}
          src={`https://the-fight-lab-bucket.s3-us-west-2.amazonaws.com/img/users/${member.photo}`}
          alt={'Current User'}
        />
      </div>
      <p
        className={
          isActive
            ? [classes.status].join(' ')
            : [classes.status, classes.statusDanger].join(' ')
        }
      >
        {isActive ? 'Active' : 'Inactive'}
      </p>
      <h1 className={classes.name}>{member.name}</h1>
      <p className={classes.since}>
        Member since {new Date(member.createdAt).toLocaleString().split(',')[0]}
      </p>
      <div className={classes.personalBlock}>
        <h3 className={classes.personalHeader}>Personal Details</h3>
        <div className={classes.infoBlock}>
          <p>Phone Number</p>
          <p>
            {member.phoneNumber
              ? `${member.phoneNumber.substring(
                  0,
                  3
                )}-${member.phoneNumber.substring(
                  3,
                  6
                )}-${member.phoneNumber.substring(6, 10)}`
              : member.phoneNumber}
          </p>
        </div>
        <div className={classes.infoBlock}>
          <p>Email</p>
          <p>{member.email}</p>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
