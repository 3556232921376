import React, { useEffect, useState } from 'react';
import classes from './Filter.module.scss';
import Backdrop from '../../../components/UI/Backdrop/Backdrop';

type Props = {
  setMonthNo: (monthNo: (el: any) => number | any) => void;
  monthNo: number;
  year: number;
  setYear: (year: any) => void;
  onPayClick: (event: any) => void;
};

const Filter = (props: Props) => {
  const [filterActive, setFilterActive] = useState(false);
  const [filterStyle, setFilterStyle] = useState([classes.filterBox]);
  const [filterBtnStyle, setFilterBtnStyle] = useState([classes.filter]);
  const onFilterClick = () => {
    setFilterActive(!filterActive);
  };

  useEffect(() => {
    if (filterActive) {
      setFilterStyle(prevState => {
        return [...prevState, classes.filterBoxActive];
      });
      setFilterBtnStyle(prevState => {
        return [...prevState, classes.filterActive];
      });
    } else {
      setFilterStyle([classes.filterBox]);
      setFilterBtnStyle([classes.filter]);
    }
  }, [filterActive]);

  return (
    <>
      <Backdrop show={filterActive} clicked={onFilterClick} />
      <div className={filterStyle.join(' ')}>
        <div className={classes.months}>
          <div className={classes.arrows}>
            <svg
              className={classes.icon}
              onClick={() =>
                props.setMonthNo((el: number) => {
                  if (el === 1) {
                    props.setYear(props.year - 1);
                    return (el = 12);
                  }
                  return el - 1;
                })
              }
            >
              <use xlinkHref={`/img/icons.svg#icon-arrow-left`}></use>
            </svg>
            <p className={classes.number}>{props.monthNo}</p>
            <svg
              className={classes.icon}
              onClick={() =>
                props.setMonthNo(el => {
                  if (el === 12) {
                    props.setYear(props.year + 1);
                    return (el = 1);
                  }
                  return el + 1;
                })
              }
            >
              <use xlinkHref={`/img/icons.svg#icon-arrow-right`}></use>
            </svg>
          </div>
        </div>
        <div className={classes.yearBox}>
          <div className={classes.arrows}>
            <svg
              className={classes.icon}
              onClick={() =>
                props.setYear((el: any) => {
                  return el - 1;
                })
              }
            >
              <use xlinkHref={`/img/icons.svg#icon-arrow-left`}></use>
            </svg>
            <p className={classes.number}>{props.year}</p>
            <svg
              className={classes.icon}
              onClick={() =>
                props.setYear((el: any) => {
                  return el + 1;
                })
              }
            >
              <use xlinkHref={`/img/icons.svg#icon-arrow-right`}></use>
            </svg>
          </div>
        </div>
        <div className={classes.paymentTypeBox}>
          <p
            onClick={() => props.onPayClick('stripe')}
            className={classes.lilBox}
          >
            Stripe
          </p>
          <p
            onClick={() => props.onPayClick('cash')}
            className={classes.lilBox}
          >
            Cash
          </p>
          <p
            onClick={() => props.onPayClick('both')}
            className={classes.lilBox}
          >
            Both
          </p>
        </div>
      </div>
      <div onClick={onFilterClick} className={filterBtnStyle.join(' ')}>
        filter
      </div>
    </>
  );
};

export default Filter;
