import React from 'react';
import styles from './BillingCard.module.scss';

const BillingCard = props => {
  const containerClasses = [styles.infoBox];
  if (props.span2) {
    containerClasses.push(styles.span);
  }

  if (!props.clicked) {
    return (
      <div className={containerClasses.join(' ')}>
        <h3 className={styles.header}>{props.header}</h3>
        <p className={styles.content}>{props.content}</p>
      </div>
    );
  }

  return (
    <div
      className={containerClasses.join(' ')}
      onClick={() => props.clicked(props.header)}
    >
      <h3 className={styles.header}>{props.header}</h3>
      <p className={styles.content}>{props.content}</p>
    </div>
  );
};

export default BillingCard;
