import React, { useContext } from 'react';
import { AuthContext } from '../../context/authContext';
import AdminBox from './AdminBox/AdminBox';

import classes from './TopBar.module.scss';

type User = {
  role: string;
  photo: string;
  name: string;
};

const TopBar = () => {
  const authContext = useContext(AuthContext);

  let user: User | null;
  let topBar = null;

  if (authContext.isAuth) {
    user = authContext.user;
    topBar = (
      <div className={classes.TopBar}>
        <div className={classes.tbdBox}>
          <p>TBD</p>
        </div>
        <AdminBox user={user} />
      </div>
    );
  }

  return topBar;
};

export default TopBar;
