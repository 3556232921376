import { useReducer, useCallback } from 'react';
import axios from '../axios-lab';
import { useIsMounted } from './isMounted';

const initialState: HttpState = {
  loading: false,
  error: null,
  data: null,
  extra: null,
  identifier: null,
};

type HttpState = {
  loading: boolean;
  error: string | null;
  data?: any | null;
  extra?: string | null;
  identifier?: string | null;
};

type Action =
  | { type: 'SEND'; identifier: string }
  | { type: 'RESPONSE'; responseData: any; extra: string }
  | { type: 'ERROR'; error: string }
  | { type: 'CLEAR' };

const httpReducer = (curHttpState: HttpState, action: Action): HttpState => {
  switch (action.type) {
    case 'SEND':
      return {
        loading: true,
        error: null,
        data: null,
        extra: null,
        identifier: action.identifier,
      };
    case 'RESPONSE':
      return {
        ...curHttpState,
        loading: false,
        data: action.responseData,
        extra: action.extra,
      };
    case 'ERROR':
      return { loading: false, error: action.error };
    case 'CLEAR':
      return initialState;
    default:
      throw new Error('should not be reached!~');
  }
};

const useHttp = () => {
  const [httpState, dispatchHttp] = useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
    extra: null,
    identifier: null,
  });
  const isMounted = useIsMounted();

  const clear = useCallback(() => {
    dispatchHttp({ type: 'CLEAR' });
  }, []);

  const sendRequest = useCallback(
    async (
      url: string,
      method: string,
      data: any = null,
      reqExtra: any,
      identifier: string
    ) => {
      dispatchHttp({ type: 'SEND', identifier: identifier });
      try {
        const responseData = await axios({ method, url, data });
        if (isMounted.current)
          dispatchHttp({
            type: 'RESPONSE',
            responseData,
            extra: reqExtra,
          });
      } catch (err: any) {
        if (err && !err.response)
          return dispatchHttp({ type: 'ERROR', error: err.message });
        if (err.response)
          return dispatchHttp({
            type: 'ERROR',
            error: err.response.data.message,
          });
        dispatchHttp({ type: 'CLEAR' });
      }
    },
    [isMounted]
  );

  return {
    isLoading: httpState.loading,
    resData: httpState.data,
    error: httpState.error,
    sendRequest: sendRequest,
    reqExtra: httpState.extra,
    reqId: httpState.identifier,
    clear: clear,
  };
};

export default useHttp;
