import React, { useState, useEffect } from 'react';
import { objFormToArr, updateInputAndForm } from '../../../utils/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import useHttp from '../../../hooks/http';
import styles from './ChangePasswordForm.module.scss';

const ChangePasswordForm = React.memo(props => {
  const [passForm, setPassForm] = useState({
    passwordCurrent: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter Password...',
      },
      label: 'Current Password',
      value: '',
      validation: {
        required: true,
        minLength: 6,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter Password...',
      },
      label: 'New Password',
      value: '',
      validation: {
        required: true,
        minLength: 6,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
    passwordConfirm: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter Password...',
      },
      label: 'Confirm New Password',
      value: '',
      validation: {
        required: true,
        minLength: 6,
        maxLength: 100,
      },
      valid: false,
      touched: false,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const { sendRequest, resData, isLoading, error } = useHttp();
  const { alert } = props;

  useEffect(() => {
    if (error) return alert('Error', error);
    if (isLoading) return;
    if (resData) {
      return alert('Success', 'Successfully changed your password!');
    }
  }, [alert, resData, error, isLoading]);

  const changePassword = e => {
    e.preventDefault();
    const formData = {};
    for (let formElementIdentifier in passForm) {
      formData[formElementIdentifier] = passForm[formElementIdentifier].value;
    }

    sendRequest('users/updateMyPassword', 'PATCH', formData);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      passForm,
      inputIdentifier
    );

    setPassForm(updatedForm);
    setFormIsValid(valid);
  };

  let form = (
    <form
      onSubmit={changePassword}
      aria-label="passwordForm"
      className={styles.form}
    >
      {objFormToArr(passForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button btnType="Success" disabled={!formIsValid}>
          Change Password
        </Button>
      </div>
    </form>
  );

  return <div>{form}</div>;
});

export default ChangePasswordForm;
