import React from 'react';

import classes from './MobileNavigation.module.scss';

type Props = {
  open: boolean;
  clicked: () => void;
};

const MobileNavigation = (props: Props) => {
  const classNames = [classes.button];

  if (props.open) {
    classNames.push(classes.open);
  } else {
  }

  return (
    <div className={classNames.join(' ')} onClick={props.clicked}>
      <span className={classes.icon}>&nbsp;</span>
    </div>
  );
};

export default MobileNavigation;
