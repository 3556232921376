import React, { useState } from 'react';
import { objFormToArr, updateInputAndForm } from '../../../utils/utils';
import styles from './StudentForm.module.scss';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

const initialForm = {
  name: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Enter Name...',
    },
    label: 'Name',
    value: '',
    validation: {
      required: true,
      minLength: 2,
      maxLength: 60,
    },
    valid: false,
    touched: false,
  },
};

const StudentForm = props => {
  const [studentForm, setStudentForm] = useState(initialForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const { currentUser } = props;

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      studentForm,
      inputIdentifier
    );

    setStudentForm(updatedForm);
    setFormIsValid(valid);
  };

  const createOrEditForm = e => {
    e.preventDefault();

    const formData = {};
    for (let formElementIdentifier in studentForm) {
      formData[formElementIdentifier] =
        studentForm[formElementIdentifier].value;
    }

    // if (currentUser) {
    //   return props.update(form);
    // }

    props.created(formData);
    setStudentForm(initialForm);
  };

  let form = (
    <form onSubmit={createOrEditForm} aria-label="form">
      {objFormToArr(studentForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
          photo={formElement.config.photo}
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button btnType="Success" disabled={!formIsValid}>
          {currentUser ? 'Update Me' : 'Create Student'}
        </Button>

        {currentUser ? null : (
          <Button btnType="Danger" clicked={props.closed}>
            Cancel
          </Button>
        )}
      </div>
    </form>
  );

  return <div>{form}</div>;
};

export default StudentForm;
