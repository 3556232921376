import React, { MouseEventHandler } from 'react';
import classes from './SelectedMember.module.scss';
import { Membership } from '../MembershipDashboard';

type Props = {
  deleteMembership: (id: string) => void;
  membership?: Membership;
  edit: () => void;
};

const SelectedMember = (props: Props) => {
  let membership: Membership | null = null;
  if (props.membership) {
    membership = props.membership;
  }

  const dMem = () => {
    console.log('inside mouse event');
    return props.deleteMembership(membership!.id);
  };

  return (
    <div>
      <p>Account Holder: {membership?.accountHolder?.name}</p>
      <p>Student: {membership?.student?.name}</p>
      <p>Payment Method: {membership?.paymentMethod}</p>
      <p>Monthly Base Price: ${membership!.monthlyBasePrice / 100}</p>
      <p>Monthly Discount: {membership?.monthlyDiscount}</p>
      <p>Monthly Total: ${membership!.monthlyTotal / 100}</p>
      <p>Total Discount: ${membership?.totalDiscount}</p>
      <p>Total Due ${membership!.totalDue / 100}</p>
      <p>ID: {membership?.id}</p>
      <p>Type: {membership?.membershipType}</p>
      <svg className={classes.Trash} onClick={dMem}>
        <use xlinkHref={`/img/icons.svg#icon-trash`}></use>
      </svg>
      <svg className={classes.Icon} onClick={() => props.edit()}>
        <use xlinkHref={`/img/icons.svg#icon-edit`}></use>
      </svg>
    </div>
  );
};

export default SelectedMember;
