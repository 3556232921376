import React, { useState, useEffect } from 'react';

import classes from './CalendarHeader.module.scss';

const weekArray = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const CalendarHeader = props => {
  const [text, setText] = useState('Month');

  useEffect(() => {
    if (props.isWeekly) return setText('Bi-Weekly');
    setText('Month');
  }, [props.isWeekly]);

  const changeTextHandler = () => {
    if (text.includes('Month') && !props.isWeekly) return setText('Bi-Weekly');

    if (text.includes('Bi-Weekly') && props.isWeekly) return setText('Month');
  };

  const onExitTextHandler = () => {
    if (text.includes('Bi-Weekly') && !props.isWeekly) return setText('Month');

    if (text.includes('Month') && props.isWeekly) return setText('Bi-Weekly');
  };
  const month = props.date.toLocaleString('default', { month: 'long' });

  const dateHeader = window.innerWidth >= 900 ? month : month.substring(0, 3);

  return (
    <>
      <div className={classes.CalendarHeader}>
        <svg
          className={classes.Icon}
          onClick={props.iconClicked}
          data-arrow={'left'}
        >
          <use xlinkHref={`/img/icons.svg#icon-arrow-left`}></use>
        </svg>
        <h3>{`${dateHeader} ${props.date.getFullYear()}`}</h3>
        <svg
          className={classes.Icon}
          onClick={props.iconClicked}
          data-arrow={'right'}
        >
          <use xlinkHref={`/img/icons.svg#icon-arrow-right`}></use>
        </svg>
        <button
          onClick={props.clicked}
          onMouseOver={changeTextHandler}
          onMouseLeave={onExitTextHandler}
          className={classes.Button}
        >
          {text}
        </button>
      </div>
      <div className={classes.tableHeader}>
        {weekArray.map((el, i) => (
          <p key={i}>{el}</p>
        ))}
      </div>
    </>
  );
};

export default CalendarHeader;
