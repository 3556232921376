import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './NavigationLink.module.scss';

type Props = {
  navLink: string;
  name: string;
};

const NavigationLink = (props: Props) => {
  return (
    <NavLink
      to={props.navLink}
      className={navData =>
        navData.isActive
          ? `${classes.Active} ${classes.NavigationLink}`
          : classes.NavigationLink
      }
    >
      {props.name}
    </NavLink>
  );
};

export default NavigationLink;
