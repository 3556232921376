import React, { useState, useEffect } from 'react';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import { updateInputAndForm, objFormToArr } from '../../utils/utils';

import styles from './CartForm.module.scss';

const CartForm = props => {
  const [cartForm, setCartForm] = useState({
    orderNote: {
      elementType: 'textarea',
      elementConfig: {
        type: 'text',
        placeholder: 'Enter Notes...',
      },
      label: 'Note',
      value: '',
      validation: {
        required: false,
        minLength: 5,
        maxLength: 500,
      },
      valid: true,
      touched: true,
    },
    discount: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        placeholder: '0000',
      },
      label: 'Discount',
      value: 0,
      validation: {
        required: false,
        minLength: 3,
        maxLength: 6,
      },
      valid: true,
      touched: true,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);

  const { user } = props;

  useEffect(() => {
    if (user) setFormIsValid(true);
  }, [user]);

  useEffect(() => {
    if (props.stripe) {
      setCartForm({
        orderNote: {
          elementType: 'textarea',
          elementConfig: {
            type: 'text',
            placeholder: 'Enter Notes...',
          },
          label: 'Note',
          value: '',
          validation: {
            required: false,
            minLength: 5,
            maxLength: 500,
          },
          valid: true,
          touched: true,
        },
      });
    }
  }, [props.stripe]);

  const createOrder = e => {
    e.preventDefault();
    const formData = {};
    for (let formElementIdentifier in cartForm) {
      formData[formElementIdentifier] = cartForm[formElementIdentifier].value;
    }

    props.created(formData);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      cartForm,
      inputIdentifier
    );

    setCartForm(updatedForm);

    if (!user) return;
    setFormIsValid(valid);
  };

  let form = (
    <form onSubmit={createOrder} aria-label="cartForm" className={styles.form}>
      {objFormToArr(cartForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
          cart
        />
      ))}
      <div className={styles.btnGroup}>
        <Button btnType="Success" disabled={!formIsValid}>
          Purchase Items
        </Button>
        <Button btnType="Danger" clicked={props.closed}>
          Cancel
        </Button>
      </div>
    </form>
  );

  return <div>{form}</div>;
};

export default CartForm;
