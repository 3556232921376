import React, { useEffect, useContext } from 'react';

import classes from './AttendanceDash.module.scss';

import { useQuery } from '@tanstack/react-query';
import axios from '../../axios-lab';

type Instructor = {
  name: string;
};

type Class = {
  name: string;
  date: string;
  time: string;
  instructor: Instructor;
};

type Attendance = {
  class: Class;
};

const AttendanceDash = () => {
  const getAttendances = async (): Promise<Attendance[]> => {
    const { data } = await axios.get(`attendance/me`);
    return data.data.attendance;
  };

  const {
    data: attendances = [],
    error: attendanceError,
    isPending,
  } = useQuery({
    queryKey: ['attendance'],
    queryFn: getAttendances,
  });

  const displayData = attendances.map(el => {
    const newDate = new Date(el.class.date);
    return (
      <div className={classes.attendanceBox}>
        <p className={classes.className}>{el.class.name}</p>
        <p className={classes.date}>
          {newDate.toLocaleDateString()}
          <span className={classes.time}> {el.class.time}</span>
        </p>
        <p className={classes.instructor}>
          Instructor: {el.class.instructor.name}
        </p>
      </div>
    );
  });

  return (
    <div className={classes.main}>
      <div className={classes.logBox}>
        <h1 className={classes.title}>Attendance Log</h1>
        {displayData}
      </div>
      <div className={classes.stats}>
        {/*<div className={classes.statBox}>*/}
        {/*  <h2 className={classes.statTitle}>Hours Trained</h2>*/}
        {/*  <p>{attendances.length}</p>*/}
        {/*</div>*/}
        <div className={classes.statBox}>
          <h2 className={classes.statTitle}>Total Classes Attended</h2>
          <p className={classes.content}>{attendances.length}</p>
        </div>
      </div>
    </div>
  );
};

export default AttendanceDash;
