import React from 'react';
import classes from './MembershipCard.module.scss';
import { formatPrice } from '../../../utils/utils';
import { utcToFormattedDate } from '../../../utils/dateUtils';
import { Membership } from '../MembershipDashboard';

type Props = {
  mem: Membership;
  selectMembership: (mem: Membership) => void;
  deleteMembership: (id: string) => void;
};

const MembershipCard = (props: Props) => {
  let priceCSS = [classes.priceCash];
  let dateCSS = [classes.date];

  if (props.mem.paymentMethod === 'stripe') priceCSS = [classes.priceStripe];

  const oldDate = new Date(props.mem.nextDue);

  if (oldDate.getTime() < new Date().getTime()) {
    dateCSS = [classes.oldDate];
  }

  return (
    <div
      className={classes.membership}
      onClick={() => props.selectMembership(props.mem)}
    >
      <div className={classes.accountHolder}>
        <p>{props.mem?.accountHolder?.name}</p>
        <span>{props.mem.student?.name}</span>
      </div>
      <div className={classes.info}>
        <p className={priceCSS.join(' ')}>
          {formatPrice(props.mem.monthlyTotal)}
        </p>
        <p className={dateCSS.join(' ')}>
          {utcToFormattedDate(props.mem.nextDue)}
        </p>
        <p className={classes.status}>{props.mem.status}</p>
      </div>
    </div>
  );
};

export default MembershipCard;
