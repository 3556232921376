import React, { useState, useEffect } from 'react';
import { objFormToArr, updateInputAndForm } from '../../../utils/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import styles from './MemberForm.module.scss';

const initialForm = {
  name: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Enter Name...',
    },
    label: 'Name',
    value: '',
    validation: {
      required: true,
      minLength: 2,
      maxLength: 60,
    },
    valid: false,
    touched: false,
  },
  email: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'example@gmail.com',
    },
    label: 'Email',
    value: '',
    validation: {
      required: true,
      minLength: 2,
      maxLength: 60,
    },
    valid: false,
    touched: false,
  },
  phoneNumber: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: '739-872-8792',
    },
    label: 'Phone Number',
    value: '',
    validation: {
      required: true,
      minLength: 2,
      maxLength: 60,
    },
    valid: false,
    touched: false,
  },
  photo: {
    elementType: 'input',
    elementConfig: {
      type: 'file',
      placeholder: '',
    },
    label: 'Choose Photo',
    value: '',
    validation: {},
    valid: false,
    touched: false,
    photo: true,
  },
};

const MemberForm = props => {
  const [memberForm, setMemberForm] = useState(initialForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [image, setImage] = useState();
  const { currentUser } = props;

  useEffect(() => {
    if (currentUser) {
      setMemberForm(curMemberForm => {
        return {
          name: { ...curMemberForm.name, value: currentUser.name },
          email: { ...curMemberForm.email, value: currentUser.email },
          phoneNumber: {
            ...curMemberForm.phoneNumber,
            value: currentUser.phoneNumber,
          },
          photo: { ...curMemberForm.photo },
        };
      });
    }
  }, [currentUser]);

  const createOrEditForm = e => {
    e.preventDefault();
    const form = new FormData();

    for (const [key, { value }] of Object.entries(memberForm)) {
      if (key === 'photo' && image) {
        form.append(key, image);
        continue;
      }

      if (!value) continue;

      form.append(key, value);
    }

    if (currentUser) {
      return props.update(form);
    }

    props.created(form);
    setMemberForm(initialForm);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    if (inputIdentifier === 'photo') {
      setImage(event.target.files[0]);
      return setFormIsValid(true);
    }

    const [valid, updatedForm] = updateInputAndForm(
      event,
      memberForm,
      inputIdentifier
    );

    setMemberForm(updatedForm);
    setFormIsValid(valid);
  };

  let form = (
    <form className={styles.form} onSubmit={createOrEditForm} aria-label="form">
      {objFormToArr(memberForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
          photo={formElement.config.photo}
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button btnType="Success" disabled={!formIsValid}>
          {currentUser ? 'Update Me' : 'Create Member'}
        </Button>

        {currentUser ? null : (
          <Button btnType="Danger" clicked={props.closed}>
            Cancel
          </Button>
        )}
      </div>
    </form>
  );

  return <div>{form}</div>;
};

export default MemberForm;
