import React from 'react';
import classes from './SortBox.module.scss';

const SortBox = (props: any) => {
  return (
    <div className={classes.box}>
      <p className={classes.title}>Status</p>
      <ul className={classes.list}>
        {props.filters.map((el: any) => {
          for (const [key, value] of props.searchParams.entries()) {
            console.log(`${key} = ${value}`);
            if (el === value) {
              return (
                <li
                  className={classes.listItem}
                  onClick={() => console.log('yell')}
                >
                  <svg className={classes.iconActive}>
                    <use xlinkHref="/img/icons.svg#icon-check"></use>
                  </svg>
                  <p className={classes.text}>{el}</p>
                </li>
              );
            }
          }
          return (
            <li
              className={classes.listItem}
              onClick={() => props.click('status', el)}
            >
              <svg className={classes.iconInactive}>
                <use xlinkHref="/img/icons.svg#icon-check"></use>
              </svg>
              <p className={classes.text}>{el}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SortBox;
