import React, { useState } from 'react';
import styles from './MembershipForm.module.scss';
import { objFormToArr, updateInputAndForm } from '../../../utils/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

const MembershipForm = props => {
  const [membershipForm, setMembershipForm] = useState({
    monthlyBasePrice: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        placeholder: '0000',
      },
      label: 'Monthly Price',
      value: 10000,
      validation: {
        required: false,
        minLength: 4,
        maxLength: 5,
      },
      valid: true,
      touched: true,
    },
    monthlyDiscount: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        placeholder: '0000',
      },
      label: 'Discount',
      value: 0,
      validation: {
        required: false,
        minLength: 4,
        maxLength: 5,
      },
      valid: true,
      touched: true,
    },
    monthlyRecurrence: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        placeholder: '0000',
      },
      label: 'Recurrence',
      value: 1,
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
    },
    membershipType: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: '', displayValue: 'Please Choose' },
          { value: 'youth', displayValue: 'Youth' },
          { value: 'adult', displayValue: 'Adult' },
        ],
      },
      label: 'Program',
      value: 'adult',
      validation: {},
      valid: true,
    },
    startDate: {
      elementType: 'input',
      elementConfig: {
        type: 'datetime-local',
      },
      label: 'Choose Start Date',
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      value: new Date(),
      // displayValue: new Date(),
    },
    // nextDue: {
    //   elementType: 'input',
    //   elementConfig: {
    //     type: 'datetime-local',
    //   },
    //   label: 'Choose Next Due Date',
    //   validation: {
    //     required: false,
    //   },
    //   valid: true,
    //   touched: true,
    // },
  });
  const [formIsValid, setFormIsValid] = useState(false);

  const createMembership = e => {
    e.preventDefault();
    const formData = {};
    for (let formElementIdentifier in membershipForm) {
      formData[formElementIdentifier] =
        membershipForm[formElementIdentifier].value;
    }

    props.created(formData);
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const [valid, updatedForm] = updateInputAndForm(
      event,
      membershipForm,
      inputIdentifier
    );

    setMembershipForm(updatedForm);

    // if (!user) return;
    setFormIsValid(valid);
  };

  let form = (
    <form
      onSubmit={createMembership}
      aria-label="Membership Form"
      className={styles.form}
    >
      {objFormToArr(membershipForm).map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => inputChangedHandler(event, formElement.id)}
          cart
        />
      ))}
      <div className={styles.btnGroup}>
        <Button btnType="Success" disabled={!formIsValid}>
          Create Membership
        </Button>
        <Button btnType="Danger" clicked={props.closed}>
          Cancel
        </Button>
      </div>
    </form>
  );

  return <div>{form}</div>;
};

export default MembershipForm;
